<template>
  <div>
    <GChart
      type="LineChart"
      :data="lineChartData"
      :options="lineChartOptions"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
export default {
  
  components:{
      GChart,
  },
  props: ["lineChartData", "title", "symbol", "lineColor"],

  data() {
    return {
      lineChartOptions: {
        // width:720,
        height: 350,
        // curveType: "function",
        chartArea: {
          right: "1%",
          width: "85%",
          // height:350
        },
        legend: {
          position: "none",
          textStyle: {
            color: "#e5e5e5",
            fontSize: 11,
          },
        },
        colors: ["#0000FF", "#FF0000", "#F39C12", "#808080"],
        vAxis: {
          format: " ",
          title: '',
          titleTextStyle : {
            "italic" : false,
            "color":"#1b1c1d",
            "fontSize":12,
            "fontFamily": "proxima-nova"
          },
          textStyle: {fontSize: 11 },
          baselineColor: "#ccc",
          gridDashType: "none",
          gridlines:{
              color:"#606060"
          }
        },
        hAxis: {
          textStyle: {fontSize: 11},
          slantedText:true,
          baselineColor: "red",
          gridDashType: "none",
          gridlines:{
              color:"#606060"
          }
        },
        tooltip:{
          // tranform:'rotate(180deg)',
          // isHtml:true,
          position:'left',
          textStyle:{
            fontSize:11,
            }
        },
        pointSize: 5,
        animation: {
          duration: 500,
          startup: true
        }
      },
    };
  },
  created(){
    this.lineChartOptions.vAxis.title = this.title;
    this.lineChartOptions.vAxis.format = `${this.symbol} `;
    this.lineChartOptions.colors = this.lineColor;
  },
  updated(){
    this.lineChartOptions.colors = this.lineColor;
  }
  
};
</script>

<style></style>
