<template>
  <div class="order-cards" :style="applyCardBackground(cardIndex)">
    <div class="p-2">
      <div>
        <p
          class="mb-0 analysis-card-title"
        >
          {{ cardTitle }}
          <img
            class="info-icon ml-1 mr-auto" src="../assets/info-icon.png"
            v-b-popover.hover.top.html="tipMethod(cardIndex)"
            >
        </p>
      </div>
      <div class="mr-2 d-flex justify-content-between align-items-center">
        <span class="analysis-card-value">
          {{ cardValue }}
        </span>
        <!-- <img :src="require(`../assets/SVG/${cardImg}`)" width="50px" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cardTitle", "cardValue", "cardImg", "cardIndex"],
  methods: {
    tipMethod(index) {
      if (index === 0) {
        return "<span>" + "Number of products sold in overall orders" + "</span>";
      }
      if (index === 1) {
        return "<span>" + "Total Number of quantity ordered" + "</span>";
      }
      if (index === 2) {
        return (
          "<span>" + "Total sales Amount for the orders received" + "</span>"
        );
      }
      if (index === 3) {
        return (
          "<span>" + " Average number of units ordered per order item" + "</span>"
        );
      }
      if (index === 4) {
        return "<span>" + "Average sales per order item" + "</span>";
      }
    },

      applyCardBackground(index) {
      if (index === 0) {
        return "border-left:4px solid #1D408E;";
      }
      if (index === 1) {
        return "border-left:4px solid #F43B3B;";
      }
      if (index === 2) {
        return "border-left:4px solid #EDBD1B;";
      }
      if (index === 3) {
        return "border-left:4px solid #741BED;";
      }
      if (index === 4) {
        return "border-left:4px solid #60C7FD;";
      }
    },
  },
};
</script>
<style scoped>
/* ::v-deep .popover-body {
  background-color: #d6eaf8 !important;
  border: 1px solid #d6eaf8;
  border-radius: 0.3rem;
} */
.order-cards {
  border-radius: 5px;
  box-shadow: 0px 1px 10px #40404029;
  min-height: 90px;
  padding: .5rem;
  margin:1rem 0rem;
  background-color: #FFFFFF;
}
.analysis-card-title{
  color:#1B1C1D;
  font-weight:700;
  letter-spacing:0.5px;
  font-size:12px;
  display: flex;
  justify-content: space-between;
}
.analysis-card-value{
  color:#1B1C1D; 
  font-size:20px; 
  font-weight:600;
  margin-top: 5%;
}
.info-icon{
    /* font-size: 10px;
    border: 1px solid rgb(239, 135, 0);
    color: rgb(239, 135, 0);
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer;
    width: 15px;
    height: 15px;
    text-align: center;
    align-items: center; */
  height:15px;
  cursor: default;
}
</style>
