<template>
  <div>
    <GChart
      id='googleChart'
      type="BarChart"
      :data="columnChartData"
      :options="columnChartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
export default {
  props: ["columnChartData"],
  components: {
    GChart
  },
  created(){
    // this.curveChartBar();
  },
  mounted(){
    
  },
  data() {
    return {
      columnChartOptions: {
        // width:1250,
        bar: { groupWidth: "25%" },
        pointsVisible:true,
        height: 400,
        // width: "50%",
        chartArea: {
          top:50,
          bottom: 50,
          left: 120,
          width:500,
          height:500,
          right:50,
        },
        curveType: "function",
        legend: {
          position: "none",
        },
        responsive: true,
        maintainAspectRatio: false,
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        colors: ["#8539F6"],
        vAxis: {
          scaleType: 'log',
          titleTextStyle : {bold: 'true'},
          textStyle: {fontSize: 12,textAlign:"center",marginLeft:"200"}
        },
        hAxis: {
          textStyle: {fontSize: 12,textAlign:"center",paddingTop: '200'}
        },
        animation: {
          duration: 500,
          startup: true
        }
      },
    };
  },
  methods:{
    curveChartBar(){
      var container = document.getElementById('googleChart')
      var chart = new google.visualization.ColumnChart(container)
      google.visualization.events.addListener(chart, 'ready', changeBorderRadius);
      google.visualization.events.addListener(chart, 'select', changeBorderRadius);
      google.visualization.events.addListener(chart, 'onmouseover', changeBorderRadius);
      google.visualization.events.addListener(chart, 'onmouseout', changeBorderRadius);

      function changeBorderRadius() {
        chartColumns = container.getElementsByTagName('rect');
        Array.prototype.forEach.call(chartColumns, function(column) {
          if ((colors.indexOf(column.getAttribute('fill')) > -1) ||
              (column.getAttribute('fill') === 'none') ||
              (column.getAttribute('stroke') === '#ffffff')) {
            column.setAttribute('rx', 20);
            column.setAttribute('ry', 20);
          }
        });
      }
    }
  }
};
</script>

<style></style>
