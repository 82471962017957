<template>
  <div class="dashbaord-overview-position">
    <!-- for mobile filter -->
    <div class="open-mobile-filter" v-if="isMobile_filter_open">
      <div class="row mx-2">
        <div class="col-2 pt-4">
          <span @click="closeApplyFilter">
            <img src="../../assets/SVG/arrow-back.svg" >
          </span>
        </div>
        <div
          class="col-8 pt-0 d-flex justify-content-center"
          style="border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
        >
          <span
            class="text-white bg-dark py-3 px-4"
            style="font-size:20px; border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
            >LOGO</span
          >
        </div>
        <div class="col-2"></div>
      </div>
      <!-- for compare sales mobile filter -->
      <div v-if="isCompare_mobileFilter">
        <b-card class="mx-2 mt-5">
          <div class="row">
            <div class="col-md-3 col-12 date-select" style="">
              <label class=" tree-label">Date Range</label>
              <date-picker
                id="mobile-compare-date"
                v-model="compareRangepicaker"
                type="date"
                lang="en"
                confirm
                range
                :shortcuts="compareShortcuts"
                @clear="clearCompareDate($event)"
                @confirm="getCompareDate($event)"
              ></date-picker>
            </div>

            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Fulfilment Channel'"
                :rootOptions="fulfillmentOptions"
                :selectedValue="compareSalesfulfillmentvalues"
                @selectedField="onCompareSalesfulfillment($event)"
              />
            </div>

            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Channels'"
                :rootOptions="channelListOptions"
                :selectedValue="compareSalesChannelValues"
                @selectedField="onCompareSalesChannels($event)"
              />
            </div>
            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Brands'"
                :rootOptions="brandsListOptions"
                :selectedValue="compareSalesBrandsValues"
                @selectedField="onCompareSalesBrands($event)"
              />
            </div>
          </div>
        </b-card>
      </div>
      <!-- for sales analysis mobile filter -->
      <div v-if="isSalesAnalysis_mobileFilter">
        <b-card class="mx-2 mt-5">
          <div class="row">
            <div class="col-md-3 col-12 mt-auto date-select">
              <label class=" tree-label">Date Range</label>
              <date-picker
                id="mobile-sales-date"
                v-model="rangepicaker"
                type="date"
                lang="en"
                confirm
                range
                :shortcuts="filterShortcuts"
                @clear="clearPurchaseDate($event)"
                @confirm="getPurchaseDate($event)"
              ></date-picker>
            </div>

            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Fulfilment Channel'"
                :rootOptions="fulfillmentOptions"
                :selectedValue="fulfillmentSelectedValues"
                @selectedField="onSelectedfulfillment($event)"
              />
            </div>

            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Channels'"
                :rootOptions="channelListOptions"
                :selectedValue="selectedChannelValues"
                @selectedField="onSelectedChannels($event)"
              />
            </div>
            <div class="col-md-3 col-12">
              <w-tree-dropdown
                :labelText="'Brands'"
                :rootOptions="brandsListOptions"
                :selectedValue="selectedBrandsValues"
                @selectedField="onSelectedBrands($event)"
              />
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <!-- mobile filter end -->

    <div>
      <div class="sales-dashboard">
        <div class="d-flex justify-content-around align-items-center toggle-button">
          <div
            class="border border-primary bg-white button-groups"
            style="border-radius:20px"
          >
            <button v-if="isCompareButn"
              :class="{ activeTab: isTab == 'compare' }"
              @click="changeSalesGraphTab('compare')"
              id="compare-btn"
            >
              Compare Sales
            </button>
            <button
              :class="{ activeTab: isTab == 'analysis' }"
              @click="changeSalesGraphTab('analysis')"
              id="sales-btn"
            >
              Sales Analysis
            </button>
          </div>
          <!-- filter button for mobile -->
          <!-- <span class="apply-filter text-primary ml-2" @click="onApplyFilter"
            >
              <img src="../../assets/SVG/filter-results-button.svg" >
            </span
          > -->
          <!-- end -->
        </div>
        
      
        <!-- compare sales section start-->
        <section class="compare-sales-graph" v-if="isCompareSales">
          <div :class="isCompareSalesFilter?'d-block':'d-none'" class="sales-filter filter-for-desktop">
            <b-card class="m-2 d-flex">
              <div class="row">
                <div class="col-md-3 col-12 date-select" style="">
                  <label class=" tree-label">Date Range</label>
                  <date-picker
                    v-model="compareRangepicaker"
                    id="compare-date"
                    type="date"
                    lang="en"
                    confirm
                    range
                    :shortcuts="compareShortcuts"
                    @clear="clearCompareDate($event)"
                    @confirm="getCompareDate($event)"
                  ></date-picker>
                </div>

                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Fulfilment Channel'"
                    :rootOptions="fulfillmentOptions"
                    :selectedValue="compareSalesfulfillmentvalues"
                    @selectedField="onCompareSalesfulfillment($event)"
                  />
                </div>

                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Channels'"
                    :rootOptions="channelListOptions"
                    :selectedValue="compareSalesChannelValues"
                    @selectedField="onCompareSalesChannels($event)"
                  />
                </div>
                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Brands'"
                    :rootOptions="brandsListOptions"
                    :selectedValue="compareSalesBrandsValues"
                    @selectedField="onCompareSalesBrands($event)"
                  />
                </div>
              </div>
              <img
                class="mt-3 close-filter"
                src="../../assets/CloseModel.svg"
                width="15px"
                style="cursor:pointer"
                @click="showCompareSalesFilter"
              />
            </b-card>
          </div>
          <div class="col-md-2 mt-3 mb-2 py-0" @click="showCompareSalesFilter" id="filter-btn">
            <img src="../../assets/SVG/desktopFilter.svg" style="cursor:pointer" />
            <span style="color:#2E8CD3; font-size:14px; cursor:pointer">Filters</span>
          </div>
          

          <b-card class="m-2">
            <!-- graphical and table view dropdown -->
            <div class="row sales-btn p-0 m-0">
              <div class="col-md-3 col-12 radioBtn" v-if="isRadio">
                <b-form-group label="First day of Week" class="d-flex">
                  <b-form-radio
                    id="sunday"
                    v-model="weekBegins"
                    name="some-radios"
                    value="sunday"
                    @change="onChangeRadioBtn($event)"
                    >Sunday</b-form-radio
                  >
                  <b-form-radio
                    id="monday"
                    class="ml-3"
                    v-model="weekBegins"
                    name="some-radios"
                    value="monday"
                    @change="onChangeRadioBtn($event)"
                    >Monday</b-form-radio
                  >
                </b-form-group>
              </div>
              <!-- <div class="col-md-6 empty-div"></div> -->
              <div class="col-md-3 ml-auto col-12">
                <w-dropdown
                  :labelId="'sales'"
                  :label-text="''"
                  :selectedValue="null"
                  :selectOption="showComparisonOptions"
                  @selectedOption="onChangeCompareFilter($event)"
                />
              </div>
            </div>
            <!-- graphical view section -->
            <section v-if="showGraphicalView">
              <div class="row compare-graph p-0 m-0">
                <div class="col-md-12 col-lg-6 col-12">
                  <!-- <b-card style="overflow:hidden" class="p-0 m-0"> -->
                    <div class="w-100">
                      <w-LineChart
                        :lineChartData="salesWiseLineChart"
                        :title="'Ordered Product Sales'"
                        :symbol="compareCorrency"
                        :lineColor="lineColor"
                        v-if="salesWiseLineChart.length"
                      />
                    </div>
                  <!-- </b-card> -->
                </div>
                <div class="col-md-12 col-lg-6 col-12">
                  <!-- <b-card style="overflow:hidden"> -->
                    <div class="">
                      <w-LineChart
                        :lineChartData="unitWiseLineChart"
                        :title="'Units Ordered'"
                        :symbol="''"
                        :lineColor="lineColor"
                        v-if="unitWiseLineChart.length"
                      />
                    </div>
                  <!-- </b-card> -->
                </div>
              </div>
                <div class="row p-0 m-0 mt-3">
                  <div class="col-12" style="margin-top:11px">
                    <strong style="font-size:16px">Compare</strong>
                  </div>
                </div>
              <!-- <div class="row p-0 m-0">
                <div class="col-md-12 col-12 pl-0"> -->
                  <div class="row d-flex justify-content-center p-0 m-0">
                    <div
                      class="col-md-4 col-12 col-lg-3 compare-sales"
                      v-for="(data, index) in compareGraphList"
                      :key="index"
                    >
                      <div
                        class="lineChart-legends-card"
                      >
                        <div class="d-flex">
                          <b-form-checkbox
                            type="checkbox"
                            :key="index"
                            v-model="data.Active"
                            @change="getRowCheckedValue($event, data.type)"
                          >
                          </b-form-checkbox>
                          <p
                            class="mb-0"
                            style="font-size:14px;"
                            :style="applyTextColor(data.type)"
                          >
                            {{ data.type }}
                          </p>
                        </div>
                        <div
                          class="ml-4"
                          style="display:flex; flex-direction:column; font-size:14px"
                        >
                          <span style="opacity:0.6">{{ data.title }}</span>
                          <span
                            >{{ data.unitsOrdered }}
                            <span style="opacity:0.6"> units </span></span
                          >
                          <span
                            >{{ compareCorrency }}
                            {{ data.productOrderedSales }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- </div>
              </div> -->
            </section>
            <!-- Table view section -->
            <div class="compare-table pt-3" v-if="showTableView">
              <b-table
                thead-class="theadClass"
                tbody-tr-class="tableStyle"
                head-variant="light"
                :items="compareSalesTables.items"
                :fields="compareSalesTables.fields"
                :busy="tableLoading"
                empty-text="No Records Found"
                show-empty
                bordered
                responsive
              >
                <template v-slot:row-details="row">
                  <div style="width:100%">
                    <div class="collapse-inline-table">
                      <table class="table ">
                        <tbody>
                          <tr
                            v-for="(data, index) in row.item.plusTableData"
                            :key="index"
                            style="background:#efefef;"
                          >
                            <td style="width:27.4%">{{ data.type }}</td>
                            <td style="text-align:center">
                              {{ data.totalOrderItems }}
                            </td>
                            <td style="text-align:center">
                              {{ data.unitsOrdered }}
                            </td>
                            <td style="text-align:center">
                              {{ compareCorrency }}
                              {{ data.productOrderedSales }}
                            </td>
                            <td style="text-align:center">
                              {{ data.averageUnitsPerOrder }}
                            </td>
                            <td style="text-align:center">
                              {{ compareCorrency }}
                              {{ data.averageSalesPerOrder }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(title)="row">
                  <span class="ml-1">
                    <span
                      @click="row.toggleDetails"
                      style="cursor:pointer;"
                      v-if="row.item.type === row.item.title"
                    >
                      <img
                        v-if="row.detailsShowing"
                        src="../../assets/minusIcon.png"
                        width="17px"
                      />
                      <img
                        v-else
                        src="../../assets/plusIcon.png"
                        width="17px"
                      />
                      <span> {{ row.item.title }}</span>
                    </span>
                    <span v-else>
                      {{ row.item.type }} {{ row.item.title }}</span
                    >
                  </span>
                </template>
                <template v-slot:cell(totalOrderItems)="row">
                  <span
                    v-if="row.item.type === row.item.title"
                    :style="
                      tableDataStyle(row.item.changeInTotalOrderItemsPerc)
                    "
                  >
                    {{ row.item.changeInTotalOrderItemsPerc }}
                  </span>
                  <span v-else>{{ row.item.totalOrderItems }}</span>
                </template>
                <template v-slot:cell(unitsOrdered)="row">
                  <span
                    v-if="row.item.type === row.item.title"
                    :style="tableDataStyle(row.item.changeInUnitsOrderedPerc)"
                  >
                    {{ row.item.changeInUnitsOrderedPerc }}
                  </span>
                  <span v-else>{{ row.item.unitsOrdered }}</span>
                </template>
                <template v-slot:cell(productOrderedSales)="row">
                  <span
                    v-if="row.item.type === row.item.title"
                    :style="
                      tableDataStyle(row.item.changeInProductOrderedSalesPerc)
                    "
                  >
                    {{ row.item.changeInProductOrderedSalesPerc }}
                  </span>
                  <span v-else
                    >{{ compareCorrency }}
                    {{ row.item.productOrderedSales }}</span
                  >
                </template>
                <template v-slot:cell(averageUnitsPerOrder)="row">
                  <span
                    v-if="row.item.type === row.item.title"
                    :style="
                      tableDataStyle(row.item.changeInAverageUnitsPerOrderPerc)
                    "
                  >
                    {{ row.item.changeInAverageUnitsPerOrderPerc }}
                  </span>
                  <span v-else>{{ row.item.averageUnitsPerOrder }}</span>
                </template>
                <template v-slot:cell(averageSalesPerOrder)="row">
                  <span
                    v-if="row.item.type === row.item.title"
                    :style="
                      tableDataStyle(row.item.changeInAverageSalesPerOrderPerc)
                    "
                  >
                    {{ row.item.changeInAverageSalesPerOrderPerc }}
                  </span>
                  <span v-else
                    >{{ compareCorrency }}
                    {{ row.item.averageSalesPerOrder }}</span
                  >
                </template>
              </b-table>
            </div>
          </b-card>
        </section>

        <section class="sales-analysis" v-if="isSaleAnalysis">
          <div :class="isSalesAnalysisFilter?'d-block':'d-none'" class="sales-filter filter-for-desktop">
            <b-card class="m-2">
              <div class="row">
                <div class="col-md-3 col-12 mt-auto date-select">
                  <label class=" tree-label">Date Range</label>
                  <date-picker
                    id="sales-date"
                    v-model="rangepicaker"
                    type="date"
                    lang="en"
                    confirm
                    range
                    :shortcuts="filterShortcuts"
                    @clear="clearPurchaseDate($event)"
                    @confirm="getPurchaseDate($event)"
                  ></date-picker>
                </div>

                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Fulfilment Channel'"
                    :rootOptions="fulfillmentOptions"
                    :selectedValue="fulfillmentSelectedValues"
                    @selectedField="onSelectedfulfillment($event)"
                  />
                </div>

                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Channels'"
                    :rootOptions="channelListOptions"
                    :selectedValue="selectedChannelValues"
                    @selectedField="onSelectedChannels($event)"
                  />
                </div>
                <div class="col-md-3 col-12">
                  <w-tree-dropdown
                    :labelText="'Brands'"
                    :rootOptions="brandsListOptions"
                    :selectedValue="selectedBrandsValues"
                    @selectedField="onSelectedBrands($event)"
                  />
                </div>
              </div>
              <img
                class="mt-3 close-filter"
                src="../../assets/CloseModel.svg"
                width="15px"
                style="cursor:pointer"
                @click="showSalesAnalysisFilter"
              />
            </b-card>
          </div>
          <div class="col-md-2 my-4 py-0" @click="showSalesAnalysisFilter">
            <img src="../../assets/SVG/desktopFilter.svg" style="cursor:pointer" />
            <span style="color:#2E8CD3; font-size:14px; cursor:pointer">Filters</span>
          </div>
          

          <!-- for Desktop -->
          <div class="sales-filter sales-card" v-if="isPermission('allow_view_order_details_sales_dashboard')">
            <div class="my-3 py-0">
              <div class="row ml-0">
                <div
                  class="col-md-2 col-12 p-0 mx-2"
                  v-for="(cardData, index) in orederCardData"
                  :key="index"
                >
                  <w-sales-card
                    :cardTitle="cardData.title"
                    :cardValue="cardData.cardValue"
                    :cardImg="cardData.imgSrc"
                    :cardIndex="index"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- for Mobile -->
          <div class="row sales-card-slider mb-3" v-if="isPermission('allow_view_order_details_sales_dashboard')">
            <div class="col-md-12 col-12">
              <VueSlickCarousel :dots="true" :centerMode="true">
                <div
                  class="px-2"
                  v-for="(cardData, index) in orederCardData"
                  :key="index"
                >
                  <w-sales-card
                    :cardTitle="cardData.title"
                    :cardValue="cardData.cardValue"
                    :cardImg="cardData.imgSrc"
                    :cardIndex="index"
                  />
                </div>
              </VueSlickCarousel>
            </div>
          </div>

          <!-- <b-card class="m-2 sales-analysis-graph"> -->
            <div class="row">
              <div class="col-md-12 col-lg-6 col-sm-12" v-if="isPermission('allow_view_best_selling_products_revenuewise_unitwise')">
                <!-- <b-card> -->
                  <!-- <template v-slot:header> -->
                    <!-- <b-card-text class="text performance-based row"> -->
                    <div class="graph-card">
                      <div class="graph-title">
                        <span class="p-0 sales-analysis-heading">Best Selling Products</span>
                        <w-dropdown
                          class="p-0"
                          :label-text="''"
                          :selectedValue="defaultValue"
                          :selectOption="sortByOptions"
                          @selectedOption="onChangeAllWise($event)"
                        />
                      </div>
                      <div class="px-4">
                        <ColumnBarCharts
                          :columnChartData="productColumnChartData"
                          v-if="productColumnChartData.length > 1"
                        />
                      </div>
                    </div>
                    <!-- </b-card-text> -->
                  <!-- </template> -->
                  
                <!-- </b-card> -->
              </div>
              <div class="col-md-12 col-lg-6 col-sm-12" v-if="isPermission('allow_view_top_selling_cities_revenuewise_unitwise')">
                <!-- <b-card> -->
                  <!-- <template v-slot:header> -->
                    <!-- <b-card-text class="text performance-based row"> -->
                    <div class="graph-card">
                      <div class="graph-title">
                        <span
                          class="p-0 sales-analysis-heading"
                          >Top Selling Cities</span
                        >
                        <w-dropdown
                          class="p-0"
                          :label-text="''"
                          :selectedValue="defaultCityWise"
                          :selectOption="sortByOptions"
                          @selectedOption="onChangeAllWise($event)"
                        />
                      </div>
                      <div class="px-4">
                        <ColumnBarCharts
                          :columnChartData="cityColumnChartData"
                          v-if="cityColumnChartData.length > 1"
                        />
                      </div>
                    </div>
                    <!-- </b-card-text> -->
                  <!-- </template> -->
                <!-- </b-card> -->
              </div>
            <!-- </div> -->

            <!-- <div class="row"> -->
              <div class="col-md-12 col-lg-6 col-sm-12" v-if="isPermission('allow_view_order_day_of_the_week_revenuewise_unitwise')">
                <!-- <b-card> -->
                  <!-- <template v-slot:header> -->
                    <!-- <b-card-text class="text performance-based row"> -->
                    <div class="graph-card">
                      <div class="graph-title">
                        <span
                          class="p-0 sales-analysis-heading"
                          >Order Days Of the Week</span
                        >
                        <w-dropdown
                          class="p-0"
                          :label-text="''"
                          :selectedValue="defaultWeekWise"
                          :selectOption="sortByOptions"
                          @selectedOption="onChangeAllWise($event)"
                        />
                      </div>
                      <div class="px-4">
                        <ColumnBarCharts
                          :columnChartData="weekColumnChartData"
                          v-if="weekColumnChartData.length > 1"
                        />
                      </div>
                    </div>
                    <!-- </b-card-text> -->
                  <!-- </template> -->
                  
                <!-- </b-card> -->
              </div>
              <div class="col-md-12 col-lg-6 col-sm-12" v-if="isPermission('allow_view_order_time_of_the_day_revenuewise_unitwise')">
                <!-- <b-card> -->
                  <!-- <template v-slot:header> -->
                    <!-- <b-card-text class="text performance-based row"> -->
                    <div class="graph-card">
                      <div class="graph-title">
                        <span
                          class="p-0 sales-analysis-heading"
                          >Order Time of the Day</span
                        >
                        <w-dropdown
                          class="p-0"
                          :label-text="''"
                          :selectedValue="defaultDayWise"
                          :selectOption="sortByOptions"
                          @selectedOption="onChangeAllWise($event)"
                        />
                      </div>
                      <div class="px-4">
                        <ColumnBarCharts
                          :columnChartData="dayColumnChartData"
                          v-if="dayColumnChartData.length > 1"
                        />
                      </div>
                    </div>
                    <!-- </b-card-text> -->
                  <!-- </template> -->
                <!-- </b-card> -->
              </div>
            </div>
          <!-- </b-card> -->
        </section>

        <!-- modal for error handling -->
        <Modal
          :trigger="alert"
          :title="'HectoCommerce'"
          :centered="true"
          :showModalTable="false"
          :dontCloseWhenClickedOutside="true"
          :dontCloseWhenEscapePressed="true"
          :dontShowCloseIcon="true"
          :modalbuttonStyle="true"
          :alert="alert"
          :alertMsg="alertMsg"
          :alertType="alertType"
          :isConfirmAlert="isConfirmAlert"
          @eventClose="closeModal()"
          @confirmOkEvent="confirmOk"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Dropdown from "../../widgets/Dropdown.vue";
import DatePicker from "vue2-datepicker";
import TreeDropdown from "../../widgets/TreeDropdown";
import ColumnBarCharts from "../../widgets/ColumnBarCharts.vue";
import LineChart from "../../widgets/LineChartGraph";
import Modal from "../../widgets/ModalWidget";
import SalesDashboardCard from "../../widgets/SalesDashboardCard.vue";
import {
  getOrderCardData,
  getFullfilmentList,
  getChannelList,
  getBrandsListing,
  getProductSorting,
  getBestSellingProduct,
  getOrderSales,
  getCompareSalesGraph,
} from "../../Service/DashboardService";
import { isAuthorized } from "../../Service/CommonService";
export default {
  props:["isGetData"],
  components: {
    ColumnBarCharts,
    "w-LineChart": LineChart,
    "w-dropdown": Dropdown,
    DatePicker,
    "w-tree-dropdown": TreeDropdown,
    Modal,
    "w-sales-card": SalesDashboardCard,
  },
  data() {
    return {
      companyID: sessionStorage.getItem("companyIds"),
      isMobile_filter_open: false,
      isCompare_mobileFilter: true,
      isSalesAnalysis_mobileFilter: false,
      // for compare sales tab click
      isTab: "",
      isCompareSales: false,
      isSaleAnalysis: false,
      // for date range picker
      lineColor: ["#0000FF", "#FF0000", "#13c8d1"],
      rangepicaker: "",
      filterShortcuts: [
        {
          text: "Today",
          onClick: () => {
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);

            this.rangepicaker = [startOfDay, startOfDay];
            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "Last Week",
          onClick: () => {
            let d1 = new Date(); // get current date
            let first = d1.getDate() - d1.getDay(); // First day is the day of the month - the day of the week
            let last = first - 6; // last day is the first day + 6

            let firstday = new Date(d1.setDate(first));
            firstday = new Date(firstday.setHours(23, 59, 59, 999));

            let d2 = new Date();
            let lastday = new Date(d2.setDate(last));
            lastday = new Date(lastday.setHours(0, 0, 0, 0));

            this.rangepicaker = [lastday, firstday];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            last = new Date(last.setHours(0, 0, 0, 0));

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "This Month",
          onClick: () => {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date();

            this.rangepicaker = [firstDay, lastDay];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            let now = new Date();
            let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
            let firstday = new Date(
              lastday.getFullYear(),
              lastday.getMonth(),
              1
            );
            this.rangepicaker = [firstday, lastday.setHours(23, 59, 59, 999)];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "This Quarter",
          onClick: () => {
            // let todays = new Date();
            // let quarters = todays.getMonth() / 3;

            // let startCurrentQuarter = new Date(
            //   todays.getFullYear(),
            //   quarters * 3,
            //   1
            // );
            // let endCurrentQuarter = new Date(
            //   startCurrentQuarter.getFullYear(),
            //   startCurrentQuarter.getMonth() + 3,
            //   0
            // );

            let todays = new Date();
            let thisMonth = todays.getMonth();
            let startMonth =
              thisMonth > 2 ? (thisMonth > 5 ? (thisMonth > 8 ? 9 : 6) : 3) : 0;
            let startCurrentQuarter = new Date(
              todays.getFullYear(),
              startMonth,
              1
            );
            let endCurrentQuarter = new Date(
              startCurrentQuarter.getFullYear(),
              startMonth + 3,
              0
            );
            endCurrentQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startCurrentQuarter, endCurrentQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
        {
          text: "Last Quarter",
          onClick: () => {
            // let todays = new Date();
            // let quarters = todays.getMonth() / 3;

            // let startLastQuarter = new Date(
            //   todays.getFullYear(),
            //   quarters * 3 - 3,
            //   1
            // );
            // let endLastQuarter = new Date(
            //   startLastQuarter.getFullYear(),
            //   startLastQuarter.getMonth() + 3,
            //   0
            // );

            let todays = new Date();
            let thisMonth = todays.getMonth() + 1;
            let thisYear = todays.getFullYear();
            let startMonth =
              thisMonth > 3
                ? thisMonth > 6
                  ? thisMonth > 9
                    ? 7
                    : 4
                  : 1
                : 10;
            let startYear = thisMonth > 3 ? thisYear : thisYear - 1;
            let startLastQuarter = new Date(startYear, startMonth - 1, 1);
            let endLastQuarter = new Date(
              startLastQuarter.getFullYear(),
              startLastQuarter.getMonth() + 3,
              0
            );
            endLastQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startLastQuarter, endLastQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.getOrderCardData();
            this.getOrderSales();
            this.defaultValue = "RevenueWise";
            this.defaultDayWise = "RevenueWise";
            this.defaultWeekWise = "RevenueWise";
            this.defaultCityWise = "RevenueWise";
          },
        },
      ],

      compareShortcuts: [
        {
          text: "Today",
          onClick: () => {
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            this.compareRangepicaker = [startOfDay, startOfDay];

            this.salesStartDate = false;
            this.salesEndDate = false;

            this.rangeType = "today";
            this.isRadio = false;
            this.weekBegins = "";

            this.todayGraph = true;
            this.yesterdayGraph = true;
            this.lastYearGraph = true;
            this.lastMonthGraph = true;

            this.thisMonthGraph = false;
            this.lastYearThisMonthGraph = false;
            this.thisYearGraph = false;
            (this.weekBegins = false),
              (this.thisWeekGraph = false),
              (this.lastWeekGraph = false),
              (this.lastYearThisWeekGraph = false),
              this.getCompareSalesGraph();
          },
        },
        {
          text: "Week to date",
          onClick: () => {
            let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            // last = new Date(last.setHours(0, 0, 0, 0));
            this.compareRangepicaker = [first, last];
            // this.salesStartDate = moment(this.compareRangepicaker[0]).valueOf();
            // this.salesEndDate = moment(this.compareRangepicaker[1]).valueOf();
            this.isRadio = true;
            this.weekBegins = "monday";
            this.salesStartDate = "";
            this.salesEndDate = "";
            this.rangeType = "week";
            (this.thisMonthGraph = false),
              (this.lastMonthGraph = false),
              (this.lastYearThisMonthGraph = false),
              (this.thisWeekGraph = true);
            this.lastWeekGraph = true;
            this.lastYearThisWeekGraph = true;
            this.todayGraph = false;
            this.yesterdayGraph = false;
            this.thisYearGraph = false;
            this.lastYearGraph = false;

            this.getCompareSalesGraph();
          },
        },
        {
          text: "Month to date",
          onClick: () => {
            let date = new Date();
            this.compareRangepicaker = [
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30),
              new Date(),
            ];

            this.salesStartDate = false;
            this.salesEndDate = false;

            this.rangeType = "month";
            this.isRadio = false;
            this.weekBegins = "";
            this.thisMonthGraph = "true";
            this.lastMonthGraph = "true";
            this.lastYearThisMonthGraph = "true";

            this.thisYearGraph = false;
            this.lastYearGraph = false;
            (this.weekBegins = false),
              (this.thisWeekGraph = false),
              (this.lastWeekGraph = false),
              (this.lastYearThisWeekGraph = false),
              (this.todayGraph = false);
            this.yesterdayGraph = false;
            this.getCompareSalesGraph();
          },
        },
        {
          text: "Year to date",
          onClick: () => {
            let date = new Date();
            this.compareRangepicaker = [
              date.setFullYear(date.getFullYear() - 1),
              new Date(),
            ];

            this.salesStartDate = false;
            this.salesEndDate = false;

            this.rangeType = "year";
            this.isRadio = false;
            this.weekBegins = "";
            this.thisYearGraph = "true";
            this.lastYearGraph = "true";

            this.thisMonthGraph = false;
            this.lastMonthGraph = false;
            this.lastYearThisMonthGraph = false;
            (this.weekBegins = false),
              (this.thisWeekGraph = false),
              (this.lastWeekGraph = false),
              (this.lastYearThisWeekGraph = false),
              (this.todayGraph = false);
            this.yesterdayGraph = false;
            this.getCompareSalesGraph();
          },
        },
      ],

      tabPLUSDETAILs: "",
      // for error handling
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      startDate: "",
      endDate: "",

      compareRangepicaker: "",
      salesStartDate: "",
      salesEndDate: "",
      rangeType: "week", //

      showGraphicalView: true,
      showTableView: false,
      tableLoading: false,

      fulfillmentOptions: [],
      fulfillmentSelectedValues: [],

      channelListOptions: [],
      selectedChannelValues: [],

      brandsListOptions: [],
      selectedBrandsValues: [],

      currency: "",
      defaultValue: "RevenueWise",
      defaultDayWise: "RevenueWise",
      defaultWeekWise: "RevenueWise",
      defaultCityWise: "RevenueWise",
      sortByOptions: [],
      selectedProductWise: "Revenue-wise",
      selectedCityWise: "Revenue-wise",
      selectedWeekWise: "Revenue-wise",
      selectedDayWise: "Revenue-wise",

      productColumnChartData: [],
      sellingChartApiRes: [],
      cityColumnChartData: [],
      weekColumnChartData: [],
      dayColumnChartData: [],

      isTablePLUS: false,
      tablePLUSdata: [],
      compareSalesfulfillmentvalues: [],
      compareSalesChannelValues: [],
      compareSalesBrandsValues: [],
      compareSalesTables: {
        items: [],
        fields: [
          {
            key: "title",
            label: "",
          },
          {
            key: "totalOrderItems",
            label: "Total Order Item",
          },
          {
            key: "unitsOrdered",
            label: "Units Ordered",
          },
          {
            key: "productOrderedSales",
            label: "Ordered Product Sales",
          },
          {
            key: "averageUnitsPerOrder",
            label: "Avg. Units/ Order Item",
          },
          {
            key: "averageSalesPerOrder",
            label: "Avg. Sales/ Order Item",
          },
        ],
      },

      orederCardData: [
        {
          title: "Total order items",
          cardValue: "",
          imgSrc: "plusOrder.svg",
        },
        {
          title: "Units ordered",
          cardValue: "",
          imgSrc: "dotOrder.svg",
        },
        {
          title: "Ordered product sales",
          cardValue: "",
          imgSrc: "downloadOrder.svg",
        },
        {
          title: "Avg. units/order item",
          cardValue: "",
          imgSrc: "shareOrder.svg",
        },
        {
          title: "Avg. sales/order item",
          cardValue: "",
          imgSrc: "rightOrder.svg",
        },
      ],

      // compare sales line chart
      unitWiseLineChart: [],
      salesWiseLineChart: [],
      compareGraphList: [],
      compareCorrency: "",
      showComparisonOptions: [
        {
          value: null,
          text: "Graphical View",
        },
        {
          value: "table",
          text: "Table View",
        },
      ],
      selectedComparison: "Graphical View",

      // month wise graph
      thisMonthGraph: "",
      lastMonthGraph: "",
      lastYearThisMonthGraph: "",
      //  year wise graph
      thisYearGraph: "",
      lastYearGraph: "",
      // week wise graph
      isRadio: false,
      weekBegins: "monday",
      thisWeekGraph: "true",
      lastWeekGraph: "true",
      lastYearThisWeekGraph: "true",
      // today wise graph
      todayGraph: "",
      yesterdayGraph: "",
      isCompareSalesFilter:false,
      isSalesAnalysisFilter:false
    };
  },

  created() {
    // if(this.isPermission("allow_view_compare_sales_graph_table")){
    //   this.isCompareSales= true;
    //   this.isSaleAnalysis= false;
    //   this.isTab='compare';
    //   this.isCompareButn=true
    // }else{
    //   this.isCompareSales= false;
    //   this.isSaleAnalysis= true;
    //   this.isTab='analysis';
    //   this.isCompareButn=false;
    // }
    

    // // default 7 days date range for compare sales
    // let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // this.compareRangepicaker = [first, last];
    // this.isRadio = true;

    // this.rangepicaker = [first, last];
    // //
    // this.getOrderCardData();
    // this.getFullfilmentList();
    // this.getChannelList();
    // this.getBrandsListing();
    // this.getProductSorting();
    // this.getOrderSales();
    // this.getCompareSalesGraph();
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {

    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },

    getData(){
      if(this.isPermission("allow_view_compare_sales_graph_table")){
        this.isCompareSales= true;
        this.isSaleAnalysis= false;
        this.isTab='compare';
        this.isCompareButn=true
      }else{
        this.isCompareSales= false;
        this.isSaleAnalysis= true;
        this.isTab='analysis';
        this.isCompareButn=false;
      }
      

      // default 7 days date range for compare sales
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.compareRangepicaker = [first, last];
      this.isRadio = true;

      this.rangepicaker = [first, last];
      //
      this.getOrderCardData();
      this.getFullfilmentList();
      this.getChannelList();
      this.getBrandsListing();
      this.getProductSorting();
      this.getOrderSales();
      this.getCompareSalesGraph();
    },
    changeSalesGraphTab(tab) {
      this.isTab = tab;
      if (tab == "compare") {
        this.isCompareSales = true;
        this.isSaleAnalysis = false;
        this.isCompare_mobileFilter = true;
        this.isSalesAnalysis_mobileFilter = false;
      } else {
        this.isCompareSales = false;
        this.isSaleAnalysis = true;
        this.isCompare_mobileFilter = false;
        this.isSalesAnalysis_mobileFilter = true;
      }
    },

    // get the order card details
    getOrderCardData() {
      getOrderCardData(
        this.startDate,
        this.endDate,
        this.selectedChannelValues,
        this.selectedBrandsValues,
        this.fulfillmentSelectedValues
      )
        .then((res) => {
          this.orederCardData[0].cardValue = res.totalOrderItems;
          this.orederCardData[1].cardValue = res.unitsOrdered;
          this.orederCardData[2].cardValue = res.orderedProductSales
            ? `${res.currencyToken} ${res.orderedProductSales}`
            : "";
          this.orederCardData[3].cardValue = res.averageUnitsPerOrder;
          this.orederCardData[4].cardValue = res.averageSalesPerOrder
            ? `${res.currencyToken} ${res.averageSalesPerOrder}`
            : "";
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getFullfilmentList() {
      getFullfilmentList()
        .then((res) => {
          if (!res.errorCode){
            let resp = res.response;
            resp.forEach((element) => {
              this.fulfillmentOptions.push({
                id: element,
                label: element,
              });
            });
          } else{
              this.alert = true;
              this.alertMsg = res.failureMessage;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
          }
          
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          res.forEach((element) => {
            this.channelListOptions.push({
              id: element.channelId,
              label: element.channelName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getBrandsListing() {
      getBrandsListing(this.companyID)
        .then((res) => {
          if (!res.errorCode){
            let resp = res.response;
            resp.forEach((element) => {
              this.brandsListOptions.push({
                id: element,
                label: element,
              });
            });
          }else{
              this.alert = true;
              this.alertMsg = res.failureMessage;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getProductSorting() {
      this.sortByOptions = [];
      getProductSorting()
        .then((res) => {
           if (!res.errorCode){
              let resp = res.response;
              resp.forEach((el) => {
                this.sortByOptions.push({
                  value: el.sortingValue,
                  text: el.text,
                });
              });
            }else{
              this.alert = true;
              this.alertMsg = res.failureMessage;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for compare sales
    getCompareDate() {
      this.salesStartDate = moment(this.compareRangepicaker[0]).valueOf();
      this.salesEndDate = moment(this.compareRangepicaker[1]).valueOf();
      this.rangeType = "custom";

      this.thisYearGraph = "true";
      this.lastYearGraph = "true";

      this.thisMonthGraph = false;
      this.lastMonthGraph = false;
      this.lastYearThisMonthGraph = false;

      this.weekBegins = false;
      this.isRadio = false;
      this.getCompareSalesGraph();
    },

    clearCompareDate() {
      // default 7 days date range for compare sales
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.compareRangepicaker = [first, last];
      this.isRadio = true;
      this.weekBegins = "monday";

      this.salesStartDate = "";
      this.salesEndDate = "";
      this.rangeType = "week";
      (this.thisMonthGraph = false),
        (this.lastMonthGraph = false),
        (this.lastYearThisMonthGraph = false),
        (this.thisWeekGraph = true);
      this.lastWeekGraph = true;
      this.lastYearThisWeekGraph = true;
      this.todayGraph = false;
      this.yesterdayGraph = false;
      this.thisYearGraph = false;
      this.lastYearGraph = false;

      this.getCompareSalesGraph();
    },

    //filter for compare sales start
    onCompareSalesfulfillment(event) {
      this.compareSalesfulfillmentvalues = event;
      this.getCompareSalesGraph();
    },
    onCompareSalesChannels(event) {
      this.compareSalesChannelValues = event;
      this.getCompareSalesGraph();
    },
    onCompareSalesBrands(event) {
      this.compareSalesBrandsValues = event;
      this.getCompareSalesGraph();
    },
    // end compare sales filter

    getPurchaseDate() {
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.defaultValue = "RevenueWise";
      this.defaultDayWise = "RevenueWise";
      this.defaultWeekWise = "RevenueWise";
      this.defaultCityWise = "RevenueWise";
      this.getOrderCardData();
      this.getOrderSales();
    },

    clearPurchaseDate() {
      // default last 7 days date range for sales analysis
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.rangepicaker = [first, last];

      this.startDate = "";
      this.endDate = "";
      this.defaultValue = "RevenueWise";
      this.defaultDayWise = "RevenueWise";
      this.defaultWeekWise = "RevenueWise";
      this.defaultCityWise = "RevenueWise";
      this.getOrderCardData();
      this.getOrderSales();
    },

    onSelectedfulfillment(event) {
      this.fulfillmentSelectedValues = event;
      this.defaultValue = "RevenueWise";
      this.defaultDayWise = "RevenueWise";
      this.defaultWeekWise = "RevenueWise";
      this.defaultCityWise = "RevenueWise";
      this.getOrderCardData();
      this.getOrderSales();
    },

    onSelectedChannels(event) {
      this.selectedChannelValues = event;
      this.defaultValue = "RevenueWise";
      this.defaultDayWise = "RevenueWise";
      this.defaultWeekWise = "RevenueWise";
      this.defaultCityWise = "RevenueWise";
      this.getOrderCardData();
      // this.getCompareSalesGraph();
      this.getOrderSales();
    },

    onSelectedBrands(event) {
      this.selectedBrandsValues = event;
      this.defaultValue = "RevenueWise";
      this.defaultDayWise = "RevenueWise";
      this.defaultWeekWise = "RevenueWise";
      this.defaultCityWise = "RevenueWise";
      this.getOrderCardData();
      // this.getCompareSalesGraph();
      this.getOrderSales();
    },

    // compare sales dropdown
    onChangeCompareFilter(event) {
      this.selectedComparison = event;
      if (this.selectedComparison == null) {
        this.showGraphicalView = true;
        this.showTableView = false;
      } else {
        this.showGraphicalView = false;
        this.showTableView = true;
      }
    },

// on dropdowns toggle - revenue-wise/unit-wise
    onChangeAllWise(event) {
      this.selectedProductWise = event;
      this.defaultValue = event;
      this.selectedCityWise = event;
      this.defaultCityWise = event;
      this.selectedWeekWise = event;
      this.defaultWeekWise = event;
      this.selectedDayWise = event;
      this.defaultDayWise = event;
      this.productColumnChartData = [];
      this.cityColumnChartData = [];
      this.weekColumnChartData = [];
      this.dayColumnChartData = [];
      if (this.selectedProductWise === "RevenueWise") {
        // this.productColumnChartData = [];
        // this.productColumnChartData = this.sellingChartApiRes.topSellingProductRevenueWise;
        this.modelProductsData(this.sellingChartApiRes.topSellingProductRevenueWise);
        this.cityColumnChartData = [];
        this.cityColumnChartData = this.sellingChartApiRes.topSellingCitesRevenueWise;
        this.weekColumnChartData = [];
        this.weekColumnChartData = this.sellingChartApiRes.salesOnWeekRevenueWise;
        this.dayColumnChartData = [];
        this.dayColumnChartData = this.sellingChartApiRes.salesOnTimeRevenueWise;
      }
      if (this.selectedProductWise === "UnitsWise") {
        // this.productColumnChartData = [];
        // this.productColumnChartData = this.sellingChartApiRes.topSellingProductUnitsWise;
        this.modelProductsData(this.sellingChartApiRes.topSellingProductUnitsWise);
        this.cityColumnChartData = [];
        this.cityColumnChartData = this.sellingChartApiRes.topSellingCitesUnitsWise.map((item,i)=> {
          if(i>0) item[0] = this.$toTitle(item[0]);
          return item;
        });
        this.weekColumnChartData = [];
        this.weekColumnChartData = this.sellingChartApiRes.salesOnWeekUnitsWise.map((item,i)=> {
          if(i>0) item[0] = this.$toTitle(item[0]);
          return item;
        });
        this.dayColumnChartData = [];
        this.dayColumnChartData = this.sellingChartApiRes.salesOnTimeUnitsWise;
      }
    },

    // data modeller for best selling products data
    modelProductsData(apiRes) {
      this.productColumnChartData = [];
      if(apiRes) {
        apiRes.forEach((data, index) => {
          if (index == 0) {
            this.productColumnChartData.push(data.filter(label => label !== "SKU"));
            this.productColumnChartData[0].push({ role: "tooltip" });
          } else {
            let valuesArray = data.filter(val => data.indexOf(val) !== 1);
            valuesArray.splice(0, 1, `SKU: ${data[1]}\n${data[0]}`);
            this.productColumnChartData.push(valuesArray);
            if (this.selectedProductWise === "Revenue-wise") {
              this.productColumnChartData[index].push(
                "SKU: " + data[1] + "\n" + 
                data[0] + "\n" +
                "Item Price: "+this.currency + " " + new Intl.NumberFormat('en-IN').format(data[2])
              );
            } else {
              this.productColumnChartData[index].push(
                "SKU: " + data[1] + "\n" + 
                data[0] + "\n" +
                "Units: "+ data[2]
              );
            }
          }
        });
      } else {
        console.log('No apiRes!');
      }
    },

    // api call for product, city, week and day wise graph
    getOrderSales() {
      getOrderSales(
        this.startDate,
        this.endDate,
        this.selectedChannelValues,
        this.selectedBrandsValues,
        this.fulfillmentSelectedValues
      )
        .then((res) => {
          this.sellingChartApiRes = [];
          this.sellingChartApiRes = res;
          this.currency = res.currencySymbol;

          this.selectedProductWise = "Revenue-wise";
          this.productColumnChartData = [];

          this.selectedCityWise = "Revenue-wise";
          this.cityColumnChartData = [];

          this.selectedWeekWise = "Revenue-wise";
          this.weekColumnChartData = [];

          this.selectedDayWise = "Revenue-wise";
          this.dayColumnChartData = [];

          if (this.selectedProductWise === "Revenue-wise") {
            this.modelProductsData(res.topSellingProductRevenueWise);
          }

          if (this.selectedCityWise === "Revenue-wise") {
            res.topSellingCitesRevenueWise.forEach((data, index) => {
              if (index == 0) {
                this.cityColumnChartData.push(data);
                this.cityColumnChartData[0].push({ role: "tooltip" });
              } else {
                data[0] = this.$toTitle(data[0]);
                this.cityColumnChartData.push(data);
                this.cityColumnChartData[index].push(
                  data[0] + "\n" + " Revenue: " + this.currency + " " + new Intl.NumberFormat('en-IN').format(data[1])
                );
              }
            });
          }
          if (this.selectedWeekWise === "Revenue-wise") {
            res.salesOnWeekRevenueWise.forEach((data, index) => {
              if (index == 0) {
                this.weekColumnChartData.push(data);
                this.weekColumnChartData[0].push({ role: "tooltip" });
              } else {
                data[0] = this.$toTitle(data[0]);
                this.weekColumnChartData.push(data);
                this.weekColumnChartData[index].push(
                  data[0] + "\n" + " Revenue: " + this.currency + " " + new Intl.NumberFormat('en-IN').format(data[1])
                );
              }
            });
          }
          if (this.selectedDayWise === "Revenue-wise") {
            res.salesOnTimeRevenueWise.forEach((data, index) => {
              if (index == 0) {
                this.dayColumnChartData.push(data);
                this.dayColumnChartData[0].push({ role: "tooltip" });
              } else {
                this.dayColumnChartData.push(data);
                this.dayColumnChartData[index].push(
                  data[0] + "\n" + " Revenue: " + this.currency + " " + new Intl.NumberFormat('en-IN').format(data[1])
                );
              }
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // api call for compare sales graph
    getCompareSalesGraph() {
      getCompareSalesGraph(
        this.compareSalesfulfillmentvalues,
        this.compareSalesChannelValues,
        this.compareSalesBrandsValues,

        this.salesStartDate,
        this.salesEndDate,

        this.rangeType,

        this.thisMonthGraph,
        this.lastMonthGraph,
        this.lastYearThisMonthGraph,

        this.thisYearGraph,
        this.lastYearGraph,

        this.weekBegins,
        this.thisWeekGraph,
        this.lastWeekGraph,
        this.lastYearThisWeekGraph,

        this.todayGraph,
        this.yesterdayGraph
      )
        .then((res) => {
          this.compareCorrency = res.rupeeSymbol;
          this.compareSalesTables.items = res.compareGraphList;

          this.unitWiseLineChart = res.unitGraphData;
          this.salesWiseLineChart = res.salesGraphData;
          this.compareGraphList = [];
          this.tablePLUSdata = [];
          res.compareGraphList.forEach((obj) => {
            if (obj.type != null && obj.title != null) {
              if (obj.type.trim() !== obj.title.trim()) {
                this.compareGraphList.push({ ...obj, Active: "true" });
              } else {
                this.tablePLUSdata.push(obj);
              }
            }
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    // call graph api when we select and unselect checkbox
    getByCheckbox() {
      getCompareSalesGraph(
        this.compareSalesfulfillmentvalues,
        this.compareSalesChannelValues,
        this.compareSalesBrandsValues,

        this.salesStartDate,
        this.salesEndDate,

        this.rangeType,

        this.thisMonthGraph,
        this.lastMonthGraph,
        this.lastYearThisMonthGraph,

        this.thisYearGraph,
        this.lastYearGraph,

        this.weekBegins,
        this.thisWeekGraph,
        this.lastWeekGraph,
        this.lastYearThisWeekGraph,

        this.todayGraph,
        this.yesterdayGraph
      )
        .then((res) => {
          this.unitWiseLineChart = res.unitGraphData;
          this.salesWiseLineChart = res.salesGraphData;
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    applyTextColor(textType) {
      if (
        textType == "Same day last week" ||
        textType == "same week last week" ||
        textType == "Same week last year" ||
        textType == "Same month last year"
      ) {
        return "color:#13c8d1";
      }
      if (
        textType == "Selected date range one year ago" ||
        textType == "Yesterday" ||
        textType == "Last week" ||
        textType == "Last month" ||
        textType == "Last year"
      ) {
        return "color:#FF0000";
      }
      if (
        textType == "Selected date range" ||
        textType == "same week" ||
        textType == "Today" ||
        textType == "This week" ||
        textType == "This Month" ||
        textType == "This year"
      ) {
        return "color:#0000FF";
      }
      if (textType == "Same day last year") {
        return "color:#808080";
      }
    },

    // show plus button details
    showPlusBtnDetail(tabData, i) {
      this.isTablePLUS = !this.isTablePLUS;
      this.tabPLUSDETAILs = tabData;
    },

    // for table view percentage neg and positive wise style
    tableDataStyle(value) {
      if (value != null) {
        if (value.replace(/%/g, "") === 0 || value.replace(/%/g, "") > 0) {
          return "color:green";
        } else {
          return "color:red";
        }
      }
    },

    // week to date dateRange
    onChangeRadioBtn(event) {
      this.weekBegins = event;

      this.rangeType = "week";
      this.thisWeekGraph = "true";
      this.lastWeekGraph = "true";
      this.lastYearThisWeekGraph = "true";

      this.salesStartDate = false;
      this.salesEndDate = false;

      (this.thisMonthGraph = false),
        (this.lastMonthGraph = false),
        (this.lastYearThisMonthGraph = false),
        (this.thisYearGraph = false),
        (this.lastYearGraph = false),
        (this.todayGraph = false),
        (this.yesterdayGraph = false),
        this.getCompareSalesGraph();
    },

    getRowCheckedValue(active, type) {
      // for month to date
      if (type == "This Month") {
        if (active == true) {
          this.thisMonthGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.thisMonthGraph = "false";
          this.lineColor = ["#FF0000", "#13c8d1"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Last month") {
        if (active == true) {
          this.lastMonthGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.lastMonthGraph = "false";
          this.lineColor = ["#0000FF", "#13c8d1"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Same month last year") {
        if (active == true) {
          this.lastYearThisMonthGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.lastYearThisMonthGraph = "false";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      // year to date
      if (type == "This year") {
        if (active == true) {
          this.thisYearGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.thisYearGraph = "false";
          this.lineColor = ["#FF0000"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Last year") {
        if (active == true) {
          this.lastYearGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.lastYearGraph = "false";
          this.lineColor = ["#0000FF"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      // week to date
      if (type == "This week") {
        if (active == true) {
          this.thisWeekGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.thisWeekGraph = "false";
          this.lineColor = ["#FF0000", "#13c8d1"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Last week") {
        if (active == true) {
          this.lastWeekGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.lastWeekGraph = "false";
          this.lineColor = ["#0000FF", "#13c8d1"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Same week last year") {
        if (active == true) {
          this.lastYearThisWeekGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        } else {
          this.lastYearThisWeekGraph = "false";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisMonthGraph = false),
            (this.lastMonthGraph = false),
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            (this.lastYearGraph = false),
            (this.todayGraph = false),
            (this.yesterdayGraph = false),
            this.getByCheckbox();
        }
      }
      // today wise
      if (type == "Today") {
        if (active == true) {
          this.todayGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.todayGraph = "false";
          this.lineColor = ["#FF0000", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Yesterday") {
        if (active == true) {
          this.yesterdayGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.yesterdayGraph = "false";
          this.lineColor = ["#0000FF", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Same day last week") {
        if (active == true) {
          this.lastMonthGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.lastMonthGraph = "false";
          this.lineColor = ["#0000FF", "#FF0000", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }
      if (type == "Same day last year") {
        if (active == true) {
          this.lastYearGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1", "#808080"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.lastYearGraph = "false";
          this.lineColor = ["#0000FF", "#FF0000", "#13c8d1"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            (this.thisYearGraph = false),
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }
      // custom date wise
      if (type == "Selected date range") {
        if (active == true) {
          this.lastYearGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            // this.thisYearGraph = false,
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.lastYearGraph = "false";
          this.lineColor = ["#FF0000"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            // this.thisYearGraph = false,
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }

      if (type == "Selected date range one year ago") {
        if (active == true) {
          this.thisYearGraph = "true";
          this.lineColor = ["#0000FF", "#FF0000"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            // this.thisYearGraph = false,
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        } else {
          this.thisYearGraph = "false";
          this.lineColor = ["#0000FF"];

          (this.thisMonthGraph = false),
            // this.lastMonthGraph = false,
            (this.lastYearThisMonthGraph = false),
            // this.thisYearGraph = false,
            // this.lastYearGraph = false,
            (this.weekBegins = false),
            (this.thisWeekGraph = false),
            (this.lastWeekGraph = false),
            (this.lastYearThisWeekGraph = false),
            this.getByCheckbox();
        }
      }
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },

    // open filter for mobile
    onApplyFilter(tab) {
      this.isMobile_filter_open = true;
    },
    closeApplyFilter() {
      this.isMobile_filter_open = false;
    },
    showCompareSalesFilter(){
      this.isCompareSalesFilter = !this.isCompareSalesFilter
    },
    showSalesAnalysisFilter(){
      this.isSalesAnalysisFilter = !this.isSalesAnalysisFilter
    }
  },
};
</script>

<style scoped>
.sales-analysis-heading {
    font-size: 16px;
    color:#1B1C1D;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
/* active tab */
.activeTab {
  background: #2185d0;
  color: #fff !important;
  border-radius: 20px;
}
.button-groups button {
  font-size: 13px;
  font-weight: 600;
  color: #2185d0;
  border: none;
  width: 137px;
  padding: 8px 20px;
}

.-filter ::v-deep .card {
  border: none;
  border-radius: 0.15rem;
}
/* .compare-sales-title ::v-deep .card-body {
  padding: 0;
} */
.lineChart-legends-card{
  box-shadow: 0px 1px 10px #40404029;
  padding: .5rem;
}
.sales-dashboard .sales-btn {
  display: flex;
  /* justify-content: flex-end; */
}
.radioBtn {
  position: relative;
  bottom: 5px;
}
.radioBtn ::v-deep fieldset div {
  display: flex;
}
.radioBtn ::v-deep label {
  font-size: 12px;
  color: #767676;
}
.radioBtn ::v-deep legend {
  font-size: 12px !important;
  color: #767676 !important;
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 500;
}
.radioBtn ::v-deep .custom-control-label::before {
  top: 0;
}
.radioBtn ::v-deep .custom-control-label::after {
  top: 0;
}

.sales-analysis-graph{
  background-color: transparent !important;
  border:0px;
  padding: 0px;
}
/* .sales-analysis-graph ::v-deep .card-body{
  padding:0px;
} */
.radioBtn ::v-deep .form-group > legend{
  color: #1B1C1D !important;
  font-weight: 600;
  font-size: 14px !important;
}
.compare-table ::v-deep .card {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.compare-table ::v-deep table thead tr th {
  text-align: center;
}
.compare-table ::v-deep table tbody tr td {
  text-align: center;
}
.compare-table ::v-deep table thead tr th:first-child {
  text-align: left;
}
.compare-table ::v-deep table tbody tr td:first-child {
  text-align: left;
}

.date-select ::v-deep .vue-daterange-picker {
  width: 250px !important;
  display: block;
}
.date-select ::v-deep .vue-daterange-picker .form-control {
  height: 37px !important;
}
.performance-based {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
}
.card-header{
  background-color: #FFFFFF;
  /* padding: 0rem 1.25rem !important; */
}
/* .card {
  border-radius: 10px;
  box-shadow: 0px 1px 10px #40404029;
} */
.compare-sales p:first-letter {
  text-transform: capitalize;
}
.compare-graph ::v-deep .card-body {
  padding-left: 0;
}
.compare-graph ::v-deep .card {
  border:0px
}
/* style for table of table view tab */
::v-deep .tableStyle {
  padding-right: 16px;
  padding-left: 10px;
  font-family: proxima-nova, sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
}
::v-deep .theadClass {
  background-color: #efefef !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #1b1c1d;
}

.sales-card-slider {
  display: none;
}
.sales-card-slider ::v-deep .slick-slider {
  width: 100%;
}
.toggle-button {
  margin-top: 25px;
}

.mobile-filter {
  display: none;
}
.open-mobile-filter {
  display: none;
}
.apply-filter {
  display: none;
}
/* .filter-for-desktop {
} */
.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
/* for Responsive */
@media only screen and (max-width: 768px) {

  /* .toggle-button {
    margin-bottom: 20px;
  } */
  .radioBtn ::v-deep .form-group {
    margin-bottom: 0;
  }
  .empty-div {
    display: none;
  }
  .sales-card {
    display: none;
  }
  .sales-analysis-heading {
    font-size: 16px;
    color:#1B1C1D;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .sales-card-slider {
    display: block;
  }
  .sales-analysis-graph {
    margin-top: 15px !important;
  }
  ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  ::v-deep .mx-calendar {
    width: 100%;
  }
  ::v-deep .mx-calendar-content {
    width: 100%;
  }
  ::v-deep .mx-datepicker-popup {
    left: 0 !important;
  }
  .button-groups button {
    width: 105px;
    padding: 5px 0px;
    font-weight: normal;
  }

  .mobile-filter {
    display: block;
  }
  /* .mobile-filter {
    margin-top: 14%;
  } */
  .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 222222;
  }
  .apply-filter {
    display: block;
    /* padding: 3px 12px;
    border: 1px solid;
    background: #fff;
    cursor: pointer;
    border-radius: 18px; */
  }
}
.graph-card{
  box-shadow: 0px 1px 10px #40404029;
  background-color: #FFFFFF;
  overflow: hidden;
  border-radius: 5px;
}
.graph-title{
  border-bottom: 1px solid #6e6e6e;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.table{
  border: 0px !important;
}
.table .thead-light tr{
  background-color: #F4F4F4  !important;
}
.table .thead-light th{
  background-color: transparent;
  border:1px solid transparent;
}
.table tr:first-child td{
  border: 0px;
}
</style>
