<template>
  <div class="dashbaord-wrapper">
    <b-tabs
      content-class="mt-0"
      ref="tabs"
      class="report-tabs"
      v-model="tabIndex"
      align="center"
    >
      <b-tab title="OVERVIEW" @click="getActiveTab('overview')" class="overview" v-if="permission[0].isPermission" active>
        <Overview ref="overviewTab" :isGetData="getDataTab === 'overview'" />
      </b-tab>
      <b-tab title="SALES" class="saless " @click="getActiveTab('sales')" v-if="permission[1].isPermission">
        <SalesTab ref="salesTab" :isGetData="getDataTab === 'sales'" />
      </b-tab>
      <b-tab title="OPERATIONS" class="operationss" @click="getActiveTab('operation')" v-if="permission[2].isPermission">
        <OperationsTab ref="operationTab" :isGetData="getDataTab === 'operation'" />
      </b-tab>

      <b-tab title="PRODUCTS" class="productss" @click="getActiveTab('products')" v-if="permission[3].isPermission">
        <ProductsTab ref="productsTab" :isGetData="getDataTab === 'products'" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SearchBar from "../widgets/SearchBar.vue";
import SalesTab from "../components/Tabs/SalesTab.vue";
import ProductsTab from "../components/Tabs/ProductsTab.vue";
import OperationsTab from "../components/Tabs/OperationsTab.vue";
import Overview from "../components/Tabs/Overview.vue";
import { isAuthorized } from "../Service/CommonService.js";
export default {
  components: {
    SearchBar,
    SalesTab,
    ProductsTab,
    OperationsTab,
    Overview,
  },
  data() {
    return {
      // tabs: [],
      tabIndex: 0,
      getDataTab:"",
      permission:[
        {
          title:"overview",
          isPermission:isAuthorized('allow_dashboard_overview')
        },
        {
          title:"sales",
          isPermission:isAuthorized('allow_sales_dashboard')
        },
        {
          title:"operation",
          isPermission:isAuthorized('allow_operations_dashboard')
        },
        {
          title:"products",
          isPermission:isAuthorized('allow_product_dashboard')
        }
      ]
    };
  },
  created(){
    if(isAuthorized('allow_dashboard_overview')){
      this.getDataTab = "overview"
    }else if(isAuthorized('allow_sales_dashboard')){
      this.getDataTab = "sales"
    }else if(isAuthorized('allow_operations_dashboard')){
      this.getDataTab = "operation"
    }else if(isAuthorized('allow_product_dashboard')){
      this.getDataTab = "products"
    }
  },
  methods:{
    getActiveTab(tabName){
      this.activeTab = tabName;
      if(this.activeTab === "overview"){
        this.$refs.overviewTab.getData();
      }
      else if(this.activeTab === "sales"){
        this.$refs.salesTab.getData();
      }
      else if(this.activeTab === "operation"){
        this.$refs.operationTab.getData();
      }
      else if(this.activeTab === "products"){
        this.$refs.productsTab.getData();
      }
    }
  }
};
</script>
<style scoped>
::v-deep .report-tabs .nav-tabs .nav-item a {font-weight: 500;}
.dash {
  margin-left: 20px;
  margin-top: 40px;
  width: 94px;
  height: 23px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1d;
}
img {
  width: 23.7px;
  height: 16.9px;
  object-fit: contain;
  /* background-color: #ffffff;  */
  display: block;
  margin-right: 20px;
}
</style>
<style>
@media screen and (max-width:768px) {
  .dashbaord-wrapper div div .nav-tabs{
    justify-content: space-evenly !important;
    border-bottom:1px solid #dee2e6 !important;
    padding-bottom: 0px !important;
  }
}

</style>
