<template>
  <div class="dashbaord-overview-position">
    <div :class="isDesktopFilterOpen?'show-filter-animation dashboard-filter':'dashboard-filter'">
      <b-card class="m-2 desktop-filter">
        <div class="row p-2">
          <div class="col-lg-4 col-md-4 col-12 py-0">
            <label class="tree-label">
              Date Range
            </label>
            <date-picker
              class="overview-date-picker"
              id="overview-date-picker"
              v-model="rangepicaker"
              type="date"
              lang="en"
              confirm
              range
              :shortcuts="dateShortcuts"
              @clear="clearDateRange($event)"
              @confirm="getSelectedDateRange($event)"
            ></date-picker>
          </div>
          <div class="col-lg-4 col-md-4 col-12 py-0">
            <w-tree-dropdown
              :labelText="'Channels'"
              :rootOptions="channelListOptions"
              :selectedValue="channelListValues"
              @selectedField="selectedChannelsCategory($event)"
            />
          </div>
          <!-- <div class="col-lg-4 col-md-4 col-12">
            <w-tree-dropdown
              :labelText="'Categories'"
              class="ml-3"
              :rootOptions="categoryListOptions"
              :selectedValue="categoryListValues"
              @selectedField="selectedCategoryList($event)"
            />
          </div> -->
          <div class="col-lg-4 col-md-4 col-12 py-0">
            <w-tree-dropdown
              :labelText="'Brands'"
              :rootOptions="brandsListOptions"
              :selectedValue="brandsListValues"
              @selectedField="selectedBrandsCategory($event)"
            />
          </div>
        </div>
        <img
          class="mt-3 close-filter"
          src="../../assets/CloseModel.svg"
          width="15px"
          style="cursor:pointer"
          @click="closeDesktopFilter"
        />
      </b-card>
    </div>
    <div class="col-lg-6 col-md-6 col-6 mb-2 mt-3 p-0 px-1" id="filter-btn">
      <img src="../../assets/SVG/desktopFilter.svg" style="cursor:pointer"  @click="openDesktop_filter"/>
      <span style="color:#2E8CD3; font-size:14px; cursor:pointer" @click="openDesktop_filter">Filters</span>
    </div>
    <!-- filter for mobile -->
    <!-- <div class="open-mobile-filter" v-if="isMobile_filter_open">
      <div class="row mx-2">
        <div class="col-2 pt-4">
          <span @click="closeApplyFilter">
            <img src="../../assets/SVG/arrow-back.svg" />
          </span>
        </div>
        <div
          class="col-8 pt-0 d-flex justify-content-center"
          style="border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
        >
          <span
            class="text-white bg-dark py-3 px-4"
            style="font-size:20px; border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
            >LOGO</span
          >
        </div>
        <div class="col-2"></div>
      </div>

      <b-card class="mx-2 mt-5">
        <div class="row">
          <div class="col-md-3">
            <label class="tree-label">
              Date Range
            </label>
            <date-picker
              class=""
              v-model="rangepicaker"
              type="date"
              lang="en"
              confirm
              range
              :shortcuts="dateShortcuts"
              @clear="clearDateRange($event)"
              @confirm="getSelectedDateRange($event)"
            ></date-picker>
          </div>

          <div class="col-md-3">
            <w-tree-dropdown
              :labelText="'Channels'"
              class=""
              :rootOptions="channelListOptions"
              :selectedValue="channelListValues"
              @selectedField="selectedChannelsCategory($event)"
            />
          </div>
          <div class="col-md-3">
            <w-tree-dropdown
              :labelText="'Brands'"
              class=""
              :rootOptions="brandsListOptions"
              :selectedValue="brandsListValues"
              @selectedField="selectedBrandsCategory($event)"
            />
          </div>
        </div>
      </b-card>
    </div> -->
    <!-- mobile filter end -->

    <!-- <div> -->
      <!-- filter button for mobile -->
      <!-- <div class="mobile-filter">
        <div class="text-right">
          <p class="mb-0 mx-2 text-primary">
            <span class="apply-filter" @click="onApplyFilter"
              >
              <img src="../../assets/SVG/filter-results-button.svg" >
              </span
            >
          </p>
        </div>
      </div> -->
      <!-- end -->

      

      <div class="row p-0 overview-card" v-if="isPermission('allow_view_order_details_dashboard_overview')">
        <div
          class="col-md-2 col-12 px-2"
          v-for="(data, index) in cardData"
          :key="index"
          id="card-data"
        >
          <w-dashboard-card
            :cardTitle="data.cardTitle"
            :totalUsage="data.totalUsage"
            :orders="data.orders"
            :cardStyle="data.cardStyle"
            :routePath="data.routePath"
            :key="index"
            :icon="data.icon"
          />
        </div>
      </div>
      <!-- card slider for mobile -->
      <div class="row overview-card-slider mb-3" v-if="isPermission('allow_view_order_details_dashboard_overview')">
        <div class="col-md-12 col-12 overflow-hidden">
          <VueSlickCarousel :dots="true" :centerMode="true">
            <div class="px-2" v-for="(data, index) in cardData" :key="index">
              <w-dashboard-card
                :cardTitle="data.cardTitle"
                :totalUsage="data.totalUsage"
                :orders="data.orders"
                :cardStyle="data.cardStyle"
                :routePath="data.routePath"
                :icon="data.icon"
                :key="index"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <!-- end -->

      <div class="graph-section" v-if="isPermission('allow_view_sales_graph')">
        <b-card class="m-2" style="box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16)">
          <template v-slot:header>
            <b-card-text class="sales-graph-title">Sales Graph</b-card-text>
          </template>
          <!-- <MixedChart /> -->
          <!-- <div class="graph-section" v-if="chartData.length > 1"> -->
          <w-graph
            class="p-0 m-0"
            style="background:white;"
            type="ColumnChart"
            :data="chartData"
            :options="chartOptions"
            v-if="chartData.length > 1"
            :resizeDebounce="500"
          />
          <!-- </div> -->
          <p v-else class="text-danger text-center">
            <!-- There is no graph data. -->
          </p>
        </b-card>
      </div>

      <div class="row my-4" v-if="isPermission('allow_view_channelwise_sales_info')">
        <div class="col-md-12">
          <b-card
            class="m-2"
            style="border-radius: 5px; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);"
          >
            <template v-slot:header>
              <strong style="font-size:18px">Channel Wise Sales</strong>
            </template>

            <div class="channelSalesTable">
              <b-table
                thead-class="theadClass"
                tbody-tr-class="tableStyle"
                head-variant="light"
                :items="channelWiseSalesTable.items"
                :fields="channelWiseSalesTable.fields"
                :busy="tableLoading"
                empty-text="No Records Found"
                show-empty
                responsive
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(revenue)="data">
                  <label>
                    {{ overViewCardDetails.currency }} {{ data.item.revenue }}
                  </label>
                </template>

                <template v-slot:cell(returnedOrders)="data">
                  <label>
                    {{ data.item.returnedOrders }}
                    <span
                      >( {{ overViewCardDetails.currency }}
                      {{ data.item.returnedRevenue }} )</span
                    >
                  </label>
                </template>
              </b-table>
              <!-- <w-table
                :tableData="channelWiseSalesTable"
                :tableLoading="tableLoading"
              /> -->
            </div>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card
            class="m-2"
            style="border-radius: 5px; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16); margin-top:0 !important"
          >
            <template v-slot:header>
              <div class="d-flex align-items-center">
                <div><strong style="font-size:18px">Sales Comparison</strong></div>
                <w-dropdown
                  class="w-25 ml-auto" 
                  :label-text="''"
                  :selectedValue="salesComparisonType"
                  :selectOption="salesComparisonTypeOptions"
                  @selectedOption="onChangeSalesComparisonType($event)"
                />
              </div>              
            </template>
            <div class="row justify-content-around mt-1 mb-1">
              <div v-if="orderSALESPieChartData.length > 1 || amountSALESPieChartData.length > 1" class="col border-right">
                <div class="row">
                  <div v-if="salesComparisonType === 'unit-wise'" class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Orders</h6>
                    <w-pie-chart
                      v-if="orderSALESPieChartData.length > 1"
                      :pieChartData="orderSALESPieChartData"
                    ></w-pie-chart>
                  </div>
                  <div v-if="salesComparisonType === 'revenue-wise'" class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Amount</h6>
                    <w-pie-chart
                      v-if="amountSALESPieChartData.length > 1"
                      :pieChartData="amountSALESPieChartData"
                    ></w-pie-chart>
                  </div>
                </div>
                <p class="text-center mt-4 mb-0">Sales</p>
              </div>
              <div class="col border-right" v-if="isPermission('allow_view_channelwise_returned_orders') && (overViewCardDetails.returnedOrderCount != '0')">
                <div class="row">
                  <div v-if="salesComparisonType === 'unit-wise'" class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Orders</h6>
                    <w-pie-chart
                      v-if="orderRETURNPieChartData.length > 1"
                      :pieChartData="orderRETURNPieChartData"
                    ></w-pie-chart>
                  </div>
                  <div v-if="salesComparisonType === 'revenue-wise'" class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Amount</h6>
                    <w-pie-chart
                      v-if="amountRETURNPieChartData.length > 1"
                      :pieChartData="amountRETURNPieChartData"
                    ></w-pie-chart>
                  </div>
                </div>
                <p class="text-center mt-4 mb-0">Returned</p>
              </div>
              <div class="col" v-if="isPermission('allow_view_channelwise_cancelled_orders') && (orderCANCELPieChartData.length > 1 || orderAMOUNTPieChartData.length > 1)">
                <div class="row">
                  <div class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Orders</h6>
                    <w-pie-chart
                      v-if="orderCANCELPieChartData.length > 1"
                      :pieChartData="orderCANCELPieChartData"
                    ></w-pie-chart>
                  </div>
                  <div v-if="false" class="col-md-12">
                    <h6 class="order-amount-label text-center mb-0">Amount</h6>
                    <w-pie-chart
                      v-if="orderAMOUNTPieChartData.length > 1"
                      :pieChartData="orderAMOUNTPieChartData"
                    ></w-pie-chart>
                  </div>
                </div>
                <p class="text-center mt-4 mb-0">Cancelled</p>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    <!-- </div> -->
    <!-- modal for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import DashBoardCard from "../../widgets/DashBoardCard.vue";
import { GChart } from "vue-google-charts";
import DatePicker from "vue2-datepicker";
import TreeDropdown from "../../widgets/TreeDropdown";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import PieChart from "../../widgets/PieCharts";
import {
  getOverviewListWithFilter,
  getChannelList,
  getBrandsListing,
  getSalesGraphData,
} from "../../Service/DashboardService";
import Table from "../../widgets/Table.vue";
import { isAuthorized } from "../../Service/CommonService";

export default {
  props:["isGetData"],
  components: {
    "w-dashboard-card": DashBoardCard,
    "w-graph": GChart,
    Modal,
    DatePicker,
    "w-dropdown": Dropdown,
    "w-tree-dropdown": TreeDropdown,
    "w-pie-chart": PieChart,
    "w-table": Table,
    // MixedChart
  },
  data() {
    return {
      salesComparisonType: 'revenue-wise',
      salesComparisonTypeOptions: [{value:'revenue-wise', text:'Revenue Wise'},{value:'unit-wise', text:'Unit Wise'}],
      isDesktopFilterOpen: false,
      companyID: sessionStorage.getItem("companyIds"),
      isMobile_filter_open: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,

      rangepicaker: "",
      startDate: "",
      endDate: "",
      selectedDateType: "",
      dateShortcuts: [
        {
          text: "Today",
          onClick: () => {
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            let endofDay = new Date();
            endofDay.setHours(23, 59, 59, 999);

            this.rangepicaker = [startOfDay, endofDay];
            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "Last Week",
          onClick: () => {
            let d1 = new Date(); // get current date
            let first = d1.getDate() - d1.getDay(); // First day is the day of the month - the day of the week

            let last = first - 6; // last day is the first day + 6
            let firstday = new Date(d1.setDate(first));
            firstday = new Date(firstday.setHours(23, 59, 59, 999));

            let d2 = new Date();
            let lastday = new Date(d2.setDate(last));
            lastday = new Date(lastday.setHours(0, 0, 0, 0));

            this.rangepicaker = [lastday, firstday];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            last = new Date(last.setHours(0, 0, 0, 0));

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "This Month",
          onClick: () => {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // let lastDay = date;
            // lastDay = new Date(lastDay.setHours(0, 0, 0, 0));
            let lastDay = new Date();
            this.rangepicaker = [firstDay, lastDay];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            let now = new Date();
            let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
            let firstday = new Date(
              lastday.getFullYear(),
              lastday.getMonth(),
              1
            );

            this.rangepicaker = [firstday, lastday.setHours(23, 59, 59, 999)];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "This Quarter",
          onClick: () => {
            let todays = new Date();
            let thisMonth = todays.getMonth();
            let startMonth =
              thisMonth > 2 ? (thisMonth > 5 ? (thisMonth > 8 ? 9 : 6) : 3) : 0;
            let startCurrentQuarter = new Date(
              todays.getFullYear(),
              startMonth,
              1
            );
            let endCurrentQuarter = new Date(
              startCurrentQuarter.getFullYear(),
              startMonth + 3,
              0
            );
            endCurrentQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startCurrentQuarter, endCurrentQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
        {
          text: "Last Quarter",
          onClick: () => {
            let todays = new Date();
            let thisMonth = todays.getMonth() + 1;
            let thisYear = todays.getFullYear();
            let startMonth =
              thisMonth > 3
                ? thisMonth > 6
                  ? thisMonth > 9
                    ? 7
                    : 4
                  : 1
                : 10;
            let startYear = thisMonth > 3 ? thisYear : thisYear - 1;
            let startLastQuarter = new Date(startYear, startMonth - 1, 1);
            let endLastQuarter = new Date(
              startLastQuarter.getFullYear(),
              startLastQuarter.getMonth() + 3,
              0
            );
            endLastQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startLastQuarter, endLastQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            // this.getOverviewList();
            this.getChannelWiseSales();
            this.getSalesGraphData();
          },
        },
      ],

      brandsListOptions: [],
      brandsListValues: [],
      // categoryListValues: [],
      // categoryListOptions: [],
      channelListValues: [],
      channelListOptions: [],
      categories: [],

      orderSALESPieChartData: [],
      amountSALESPieChartData: [],
      orderCANCELPieChartData: [],
      orderAMOUNTPieChartData: [],
      orderRETURNPieChartData: [],
      amountRETURNPieChartData: [],

      overViewCardDetails: "",

      maxTotalOrder: "",
      maxTotalCancel: "",
      maxTotalReturn: "",
      maxTotalRevenue: "",
      maxTotalReturnedRevenue: "",

      cardData: [
        {
          cardTitle: "Total Orders",
          totalUsage: null,
          orders: null,
          cardStyle: "total-order",
          routePath: "/devices",
          icon: "totalOrders",
        },
        {
          cardTitle: "Cancelled Orders",
          totalUsage: null,
          orders: null,
          cardStyle: "cancelled-order",
          icon: "cancelOrder",
        },
        {
          cardTitle: "Returned Orders",
          totalUsage: null,
          orders: null,
          cardStyle: "returned-order",
          routePath: "/devices?type=media-box",
          icon: "returnedOrder",
        },
        {
          cardTitle: "Items Sold",
          totalUsage: null,
          orders: null,
          cardStyle: "items-sold",
          routePath: "/package",
          icon: "itemSold",
        },
        {
          cardTitle: "Average Per Day",
          totalUsage: null,
          orders: null,
          cardStyle: "average-per",
          routePath: "/package",
          icon: false,
        },
      ],

      channelWiseSalesTable: {
        fields: [
          {
            key: "channelName",
            label: "Channel",
            class:"text-left"
          },
          {
            key: "revenue",
            label: "Revenue",
            class: ["text-right", "pr-5"]
          },
          {
            key: "orders",
            label: "Orders",
          },
          {
            key: "canceledOrders",
            label: "Cancelled Orders",
          },
          {
            key: "returnedOrders",
            label: "Returned Orders",
          },
        ],
        items: [],
      },
      currency: "",
      chartData: [],
      chartOptions: {
        chartArea: {
          left: "6%",
          width: "85%",
        },
        tooltip: {
          textStyle: {
            fontSize: 11,
            bold: false,
          },
        },
        legend: {
          textStyle: {
            fontSize: 12,
          },
          alignment: "end",
        },
        hAxis: {
          format: "MMM-dd-yyyy",
          textStyle: { fontSize: 11 },
          slantedText: true,
        },
        vAxes: {
          0: {
            title: "Order Count",
            titleTextStyle: {
              italic: false,
              fontSize: 12,
            },
            textStyle: { fontSize: 11 },
          },
          1: {
            title: "Sales",
            format: "₹ ",
            titleTextStyle: {
              italic: false,
              fontSize: 12,
            },
            textStyle: { fontSize: 11 },
          },
        },
        // bar: { groupWidth: "20%" },
        colors: ["#FF69B4", "#2e8cd3"],
        height: 500,
        series: {
          0: {
            targetAxisIndex: 0,
            type: "bars",
          },
          1: {
            targetAxisIndex: 1,
            type: "line",
          },
        },
        pointSize: 5,
        animation: {
          duration: 500,
          startup: true
        }
      },
    };
  },

  created() {
    // for default last 7 days date
    // let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // this.rangepicaker = [first, last];
    // this.startDate = moment(this.rangepicaker[0]).valueOf();
    // this.endDate = moment(this.rangepicaker[1]).valueOf();
    // this.selectedDateType = "Custom";
    //

    // this.getChannelList();
    // this.getBrandsListing();
    // // this.getOverviewList();
    // this.getChannelWiseSales();
    // this.getSalesGraphData();
    if(this.isGetData){
      this.getData();
    }
  },
  methods: {
    onChangeSalesComparisonType(value) {
      this.salesComparisonType = value;
    },
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    getData(){
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.rangepicaker = [first, last];
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
        this.getChannelList();
        this.getBrandsListing();
        // this.getOverviewList();
        this.getChannelWiseSales();
        this.getSalesGraphData();
    },

    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelListOptions.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getBrandsListing() {
      getBrandsListing(this.companyID)
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.brandsListOptions.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // getOverviewList() {
    //   this.tableLoading = true;
    //   getOverviewListWithFilter(
    //     this.startDate,
    //     this.endDate,
    //     this.selectedDateType,
    //     this.channelListValues,
    //     this.brandsListValues
    //   )
    //     .then((res) => {
    //       this.tableLoading = false;
    //       this.overViewCardDetails = res;

    //       this.cardData[0].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.totalOrderRevenue}`;
    //       this.cardData[0].orders = this.overViewCardDetails.totalOrderCount;

    //       this.cardData[1].totalUsage = "";
    //       this.cardData[1].orders = this.overViewCardDetails.canceledOrderCount;

    //       this.cardData[2].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.returnedOrderRevenue}`;
    //       this.cardData[2].orders = this.overViewCardDetails.returnedOrderCount;

    //       this.cardData[3].orders = this.overViewCardDetails.itemsSold;

    //       this.cardData[4].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.averageOrderRevenue}`;
    //       this.cardData[4].orders = this.overViewCardDetails.averageOrderCount;
    //     })
    //     .catch((err) => {
    //       this.tableLoading = false;
    //       this.alert = true;
    //       this.alertMsg = err.message;
    //       this.alertType = "Failure";
    //       this.isConfirmAlert = false;
    //     });
    // },

    getChannelWiseSales() {
      this.tableLoading = true;
      getOverviewListWithFilter(
        this.startDate,
        this.endDate,
        this.selectedDateType,
        this.channelListValues,
        this.brandsListValues
      )
        .then((res) => {
          this.tableLoading = false;
          // for dashboard card detail
          this.overViewCardDetails = res;

          this.cardData[0].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.totalOrderRevenue}`;
          this.cardData[0].orders = this.overViewCardDetails.totalOrderCount;

          this.cardData[1].totalUsage = "";
          this.cardData[1].orders = this.overViewCardDetails.canceledOrderCount;

          this.cardData[2].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.returnedOrderRevenue}`;
          this.cardData[2].orders = this.overViewCardDetails.returnedOrderCount;

          this.cardData[3].orders = this.overViewCardDetails.itemsSold;

          this.cardData[4].totalUsage = `${this.overViewCardDetails.currency} ${this.overViewCardDetails.averageOrderRevenue}`;
          this.cardData[4].orders = this.overViewCardDetails.averageOrderCount;

          // for channel sales table or graph
          this.channelWiseSalesTable.items = res.channelWiseSales;
          this.pieChartCurrency = res.currency;

          // for sales, cancel and return pie charts start
          let salesORDERChart = [];
          let salesAMOUNTChart = [];
          let cancelORDERChart = [];
          let returnORDERChart = [];
          let returnAMOUNTChart = [];

          let chartLabel = ["Channels", "Orders", { role: "tooltip" }];

          this.channelWiseSalesTable.items.map((data) => {
            salesORDERChart.push([
              data.channelName,
              parseFloat(data.orders.replace(/,/g, "")),
              `${data.channelName}: ${data.orders}`,
            ]);
            salesAMOUNTChart.push([
              data.channelName,
              parseFloat(data.revenue.replace(/,/g, "")),
              `${data.channelName}: ${this.pieChartCurrency} ${data.revenue}`,
            ]);
            cancelORDERChart.push([
              data.channelName,
              parseFloat(data.canceledOrders.replace(/,/g, "")),
              `${data.channelName}: ${data.canceledOrders}`,
            ]);
            returnORDERChart.push([
              data.channelName,
              parseFloat(data.returnedOrders.replace(/,/g, "")),
              `${data.channelName}: ${data.returnedOrders}`,
            ]);
            returnAMOUNTChart.push([
              data.channelName,
              parseFloat(
                data.returnedRevenue.replace(/-/g, "").replace(/,/g, "")
              ),
              `${data.channelName}: ${this.pieChartCurrency} ${data.returnedRevenue}`,
            ]);
          });

          this.orderSALESPieChartData = [chartLabel, ...salesORDERChart];
          this.amountSALESPieChartData = [chartLabel, ...salesAMOUNTChart];
          this.orderCANCELPieChartData = [chartLabel, ...cancelORDERChart];
          this.orderRETURNPieChartData = [chartLabel, ...returnORDERChart];
          this.amountRETURNPieChartData = [chartLabel, ...returnAMOUNTChart];

          // for sales, cancel and return pie charts end

          let salesChannel = res.channelWiseSales || [];
          // get the max total sales wise order
          let ordr = (a) => a.reduce((x, y) => x + y, 0);
          let orderTotal = ordr(
            salesChannel.map((x) => Number(x.orders.replace(/,/g, "")))
          );
          this.maxTotalOrder = orderTotal.toFixed(2);

          // get the max total sales wise cancel
          let cancel = (a) => a.reduce((x, y) => x + y, 0);
          let cancelTotal = cancel(
            salesChannel.map((x) => Number(x.canceledOrders))
          );
          this.maxTotalCancel = cancelTotal.toFixed(2);

          // get the max total sales wise return
          let retrn = (a) => a.reduce((x, y) => x + y, 0);
          let retrnTotal = retrn(
            salesChannel.map((x) => Number(x.returnedOrders))
          );
          this.maxTotalReturn = retrnTotal.toFixed(2);

          // get the max total sales wise Revenue
          let revenue = (a) => a.reduce((x, y) => x + y, 0);
          let revenueTotal = revenue(
            salesChannel.map((x) => Number(x.revenue.replace(/,/g, "")))
          );
          this.maxTotalRevenue = revenueTotal.toFixed(2);

          // get the max total sales wise RETURNED Revenue
          let returnedRev = (a) => a.reduce((x, y) => x + y, 0);
          let returnedRevTotal = returnedRev(
            salesChannel.map((x) => Number(x.returnedRevenue.replace(",", "")))
          );
          this.maxTotalReturnedRevenue = returnedRevTotal.toFixed(2);
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for sales graph
    getSalesGraphData() {
      getSalesGraphData(
        this.startDate,
        this.endDate,
        this.selectedDateType,
        this.channelListValues,
        this.brandsListValues
      ).then((res) => {
        this.chartData = [];
        let resp = res.dualGraph;
        this.currency = res.rupeeSymbol;
        resp.forEach((data, index) => {
          if (index == 0) {
            this.chartData.push(data);
            this.chartData[0].push({ role: "tooltip" });
          } else {
            this.chartData.push(data);
            this.chartData[index].push(
              data[0] + "\n" + " Sales: " + this.currency + " " + data[2]
            );
          }
        });
      });
    },

    selectedChannelsCategory(event) {
      this.channelListValues = event;
      // this.getOverviewList();
      this.getChannelWiseSales();
      this.getSalesGraphData();
    },
    selectedBrandsCategory(event) {
      this.brandsListValues = event;
      // this.getOverviewList();
      this.getChannelWiseSales();
      this.getSalesGraphData();
    },

    // clear date
    clearDateRange() {
      // default last 7 days date
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.rangepicaker = [first, last];
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";

      // this.getOverviewList();
      this.getChannelWiseSales();
      this.getSalesGraphData();
    },
    // selected custom date range
    getSelectedDateRange() {
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
      // this.getOverviewList();
      this.getChannelWiseSales();
      this.getSalesGraphData();
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // open filter for mobile
    onApplyFilter() {
      this.isMobile_filter_open = true;
    },
    closeApplyFilter() {
      this.isMobile_filter_open = false;
    },
    // for open a desktop filter
    openDesktop_filter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter() {
      this.isDesktopFilterOpen = false;
    },
  },
};
</script>

<style scoped>
.dashboard-filter{
  display: none;
}
.show-filter-animation{
  /* animation-name:show-filter-animation;
  animation-duration: .5s;
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1); */
  display: block;
  margin-top: 2rem;
}
@keyframes show-filter-animation {
  0%{
    transform: translateY(-10px);
    opacity: .2;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
}
.dashboard-filter ::v-deep .card {
  border-radius: 5px;
  padding: 10px 0px;
}
.dashboard-filter ::v-deep .custom-select {
  height: auto;
}

::v-deep .theadClass {
  background-color: #efefef !important;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #1b1c1d;
}
::v-deep .tableStyle {
  padding-right: 16px;
  padding-left: 10px;
  font-family: proxima-nova, sans-serif !important;
  font-size: 14px;
  text-align: center;
  color: #1b1c1d;
}
.order-amount-label {
  font-family: proxima-nova, sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
  opacity: 0.5;
  padding-top: 7px;
}
/* .channelSalesTable ::v-deep table tbody {
  border-bottom: 1px solid #dee2e6;
}
.channelSalesTable ::v-deep table thead tr th:first-child {
  text-align: left;
}
.channelSalesTable ::v-deep table tbody tr td:first-child {
  text-align: left;
} */

/* tooltip style */
::v-deep .popover-body {
  border-radius: 0.3rem;
  padding: 0px 4px;
}
.overview-card {
  margin: 20px 0px;
}
.overview-card-slider {
  display: none;
}
.graph-section ::v-deep .card {
  border-radius: 5px;
  box-shadow: 0px 1px 3px #00000029;
}
.graph-section ::v-deep .card-header {
  background-color: #ffffff;
}
.graph-section ::v-deep .card-body {
  padding: 0 !important;
  overflow: hidden;
  margin: 20px 0px;
}
.sales-graph-title {
  font-size: 18px;
  font-weight: bolder;
  color: #1b1c1d;
  background-color: #ffffff;
}
/* .mobile-filter {
  display: none;
}
.open-mobile-filter {
  display: none;
} */

.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
/* .desktop-filter {
  position: absolute;
  z-index: 2;
} */

.col-md-12 ::v-deep .card-header {
  background-color: #ffffff !important;
}
/* Responsive */
@media only screen and (max-width: 768px) {
  .overview-card {
    display: none;
  }
  .overview-card-slider {
    display: flex;
    padding: 10px;
    width: 100%;
    /* padding-top: 10px; */
  }
  .overview-card-slider ::v-deep .status-card {
    height: 110px;
  }
  /* .desktop-filter {
    display: none;
  } */
  /* .mobile-filter {
    display: block;
    margin-top: 73px;
    margin-top: 60px;
  } */
  .desktop-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .desktop-filter ::v-deep .mx-calendar {
    width: 100%;
  }
  .desktop-filter ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .desktop-filter ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }

  /* .dashbaord-overview-position {
    position: relative;
  } */
  /* .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    z-index: 2;
  } */
  .sales-graph-title {
    background-color: #ffffff;
  }
}
</style>
