<template>
  <div>
    <GChart type="PieChart" :data="pieChartData" :options="pieChartOptions" />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
export default {
  components:{
    "GChart":GChart
  },
  props: ["pieChartData", "symbol"],

  data() {
    return {
      pieChartOptions: {
        // width: 650,
        // height: 400,
        chartArea: {
          // right: "1%",
          // width: "90%",
          height: "75%"
        },
        pieHole: 0.6,
        colors: [
          "#79ADDC",
          "#B2DF8A",
          "#2E8CD3",
          "#00B5FF",
          "#DD609B",
          "#FFC09F"
        ],
        // backgroundColor: "#3c4c66",
        color:"blue",
        pieSliceBorderColor: "none",
        pieSliceText: "none",
        legend: {
          position: "bottom",
          textStyle: { color: "#1b1c1d", fontSize:12 },
          labeledValueText: "value",
        },
        tooltip: {
          trigger: "focus",
          showColorCode: true,
          text: "both",
        },
      },
    };
  },
};
</script>

<style></style>
