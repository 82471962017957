import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const channelBaseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;
const baseProductURL = process.env.VUE_APP_LISTING_PRODUCT_SERVER_URL;
const baseOverviewUrl = process.env.VUE_APP_ORDER_SERVER_URL;
const baseRemittanceURL = process.env.VUE_APP_REMITTENCE_SERVER_URL;

// OVERVIEW SECTION

export const getChannelList = (companyId) => {
    const uri = `${channelBaseUrl}/api/v1/companies/channels`;
    return apiHelper.get(uri);
};

export const getBrandsListing = (companyId) => {
    const uri = `${baseProductURL}/api/v1/companies/channels/products/brands`;
    return apiHelper.get(uri);
}

export const getOverviewListWithFilter = (fromDate, toDate, dateType, channelIds, brandaIds) => {
    let uri = `${baseOverviewUrl}/api/v1/order-index/_search?`;
    if (fromDate) {
        uri = uri.concat('&start-date=', fromDate);
    }
    if (toDate) {
        uri = uri.concat('&end-date=', toDate);
    }
    if (dateType) {
        uri = uri.concat('&date-range=', dateType);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-Ids=', channelIds);
    }
    if (brandaIds && brandaIds.length > 0) {
        uri = uri.concat('&brand-names=', brandaIds);
    }

    return apiHelper.get(uri);
}

// api for sales bar graph

export const getSalesGraphData = (fromDate, toDate, dateType, channelIds, brandaIds) => {

    let uri = `${baseOverviewUrl}/api/v1/order-overview/graph?`;

    if (fromDate) {
        uri = uri.concat('&from-date=', fromDate);
    }
    if (toDate) {
        uri = uri.concat('&to-date=', toDate);
    }
    if (dateType) {
        uri = uri.concat('&date-range=', dateType);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-names=', channelIds);
    }
    if (brandaIds && brandaIds.length > 0) {
        uri = uri.concat('&brand-names=', brandaIds);
    }

    return apiHelper.get(uri);
}

// OPERATION SECTION 

export const getSlaBreachedOrdersList = () => {
    const uri = `${baseOverviewUrl}/api/v1/order-index/operations-dashboard/sla-orders`;
    return apiHelper.get(uri);
};

export const downloadOperationOrders = (type) => {
    let uri = `${baseOverviewUrl}/api/v1/order-index/operations-dashboard/sla-orders/download?type=${type}`;

    return apiHelper.downloadFile(uri);
}

// Api integration for inventory of fast moving product

export const getInventoryFastMovingTable = (channelIds, range, companyId) => {
    const uri = `${baseProductURL}/api/v1/companies/channels/products/fast-moving-products?channel-ids=${channelIds}&range=${range}`;
    return apiHelper.get(uri);
};
export const getDownloadFastMovingProduct = (channelIds, companyId) => {
    const uri = ` ${baseProductURL}/api/v1/companies/channels/products/fast-moving-products-download?channelids=${channelIds}`
    return apiHelper.downloadFile(uri);
}

// Api integration for product with zero orders

export const getProductWithZeroOrdersTable = (channelIds, companyId) => {
    const uri = `${baseProductURL}/api/v1/companies/channels/products/zero-order-products?channelids=${channelIds}`;
    return apiHelper.get(uri);
};

export const getDownloadProductZeroOrder = (channelIds, companyId) => {
    const uri = `${baseProductURL}/api/v1/companies/channels/products/zero-order-products-download?channelids=${channelIds}`;
    return apiHelper.downloadFile(uri);
}

// Api integration for Listing

export const getListingTableList = () => {
    const uri = `${baseProductURL}/api/v1/product-index/listings`;
    return apiHelper.get(uri);
}

// PRODUCT DASHBOARD

export const getProductSorting = () => {
    const uri = `${baseProductURL}/api/v1/companies/channels/products/sorting`
    return apiHelper.get(uri);
}

// api for product table
export const getProductTableList = (fromDate, toDate, dateType, channelIds, sorting, performance, pageNumber = 0, pageSize = 2, companyId) => {
        let uri = `${baseProductURL}/api/v1/companies/channels/productdashboard?page-no=${pageNumber}&page-size=${pageSize}`;
        if (fromDate) {
            uri = uri.concat('&start-date=', fromDate);
        }
        if (toDate) {
            uri = uri.concat('&end-date=', toDate);
        }
        if (dateType) {
            uri = uri.concat('&date-range=', dateType);
        }
        if (channelIds && channelIds.length > 0) {
            uri = uri.concat('&channel-id=', channelIds);
        }
        if (sorting) {
            uri = uri.concat("&sorting=", sorting)
        }
        if (performance) {
            uri = uri.concat("&performance=", performance)
        }

        return apiHelper.get(uri);

    }
    // Api for performance
export const getPerformanceOption = () => {
    const uri = `${baseRemittanceURL}/api/v1/remittance-skuwise-report/performances`;

    return apiHelper.get(uri);
}

// SALES SECTION

export const getFullfilmentList = () => {
    const uri = `${baseOverviewUrl}/api/v1/orders/fulfilled-by`;

    return apiHelper.get(uri);
}

export const getBestSellingProduct = (startDate, endDate, channelIds, brandaIds, fulfilmentIds) => {
    let uri = `${baseProductURL}/api/v1/sales-graph/fast-moving-products?`;

    if (startDate) {
        uri = uri.concat('&start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (brandaIds && brandaIds.length > 0) {
        uri = uri.concat('&brand-names=', brandaIds);
    }
    if (fulfilmentIds && fulfilmentIds.length > 0) {
        uri = uri.concat('&fulfilment=', fulfilmentIds);
    }
    return apiHelper.get(uri);
}


export const getOrderSales = (startDate, endDate, channelIds, brandaIds, fulfilmentIds) => {
        let uri = `${baseOverviewUrl}/api/v1/order-index/sales?`;

        if (startDate) {
            uri = uri.concat('&start-date=', startDate);
        }
        if (endDate) {
            uri = uri.concat('&end-date=', endDate);
        }
        if (channelIds && channelIds.length > 0) {
            uri = uri.concat('&channel-ids=', channelIds);
        }
        if (brandaIds && brandaIds.length > 0) {
            uri = uri.concat('&brand-names=', brandaIds);
        }
        if (fulfilmentIds && fulfilmentIds.length > 0) {
            uri = uri.concat('&fulfilment=', fulfilmentIds);
        }
        return apiHelper.get(uri);
    }
    // //
export const getOrderCardData = (startDate, endDate, channelIds, brandaIds, fulfilmentIds) => {
    let uri = `${baseOverviewUrl}/api/v1/orders/sales-dashboard?`

    if (startDate) {
        uri = uri.concat('&start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-Ids=', channelIds);
    }
    if (brandaIds && brandaIds.length > 0) {
        uri = uri.concat('&brand-names=', brandaIds);
    }
    if (fulfilmentIds && fulfilmentIds.length > 0) {
        uri = uri.concat('&fulfilment=', fulfilmentIds);
    }

    return apiHelper.get(uri)
}

// Compare sales graph
export const getCompareSalesGraph = (fulfilmentIds, channelIds, brandaIds, startDate, endDate, type, thisMonth, lastMonth, lastYearThisMonth, thisYear, lastYear, weekBegin, thisWeek, lastWeek, lastYearThisWeek, todayGraph, yesterdayGraph) => {
    let uri = `${baseOverviewUrl}/api/v1/orders/sales-dashboard/compare-graph?`

    if (fulfilmentIds && fulfilmentIds.length > 0) {
        uri = uri.concat('&fulfilment=', fulfilmentIds);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-Ids=', channelIds);
    }
    if (brandaIds && brandaIds.length > 0) {
        uri = uri.concat('&brand-names=', brandaIds);
    }
    if (startDate) {
        uri = uri.concat('&start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-date=', endDate);
    }

    if (type) {
        uri = uri.concat('&type=', type);
    }
    if (thisMonth) {
        uri = uri.concat('&this-month-graph=', thisMonth);
    }
    if (lastMonth) {
        uri = uri.concat('&last-month-graph=', lastMonth);
    }
    if (lastYearThisMonth) {
        uri = uri.concat('&last-year-this-month-Graph=', lastYearThisMonth);
    }
    if (thisYear) {
        uri = uri.concat('&this-year-graph=', thisYear);
    }
    if (lastYear) {
        uri = uri.concat('&last-year-graph=', lastYear);
    }

    if (weekBegin) {
        uri = uri.concat('&start=', weekBegin);
    }
    if (thisWeek) {
        uri = uri.concat('&this-week-graph=', thisWeek);
    }
    if (lastWeek) {
        uri = uri.concat('&last-week-graph=', lastWeek);
    }
    if (lastYearThisWeek) {
        uri = uri.concat('&last-year-this-week-graph=', lastYearThisWeek);
    }

    if (todayGraph) {
        uri = uri.concat('&today-graph=', todayGraph);
    }
    if (yesterdayGraph) {
        uri = uri.concat('&yesterday-graph=', yesterdayGraph);
    }

    return apiHelper.get(uri)
}