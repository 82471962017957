<template>
  <div class="operation-dashboard">
    <!-- SLA breaching and breached orders section -->
    <div v-if="!isOrderDetailsTab">

      <section class="sla-section" v-if="isPermission('allow_view_sla_breaching_orders')">
        <b-card>
          <div class="d-flex justify-content-between align-items-center px-3 py-3 sla-heading">
            <strong>SLA breaching orders</strong> <img class="info-icon ml-1 mr-auto" src="../../assets/info-icon.png" v-b-popover.hover.right="tooltips.slaBreaching">
            <p class="mb-0">
              <button
                v-if="isPermission('allow_download_sla_breaching_orders')"
                class="download-for-desktop"
                id="deskDownload"
                style="color: #2e8cd3;"
                @click="downloadSLAOrders('breaching')"
                :disabled="isSLADownloadDisabled"
              >
                Download
              </button>
              <!-- for mobile -->
              <span 
                v-if="isPermission('allow_download_sla_breaching_orders')"
                class="download-for-mobile"
                @click="downloadSLAOrders('breaching')"
                :disabled="isSLADownloadDisabled"
              >
                <img src="../../assets/download.svg" id="dowloadMob">
              </span>
            </p>
          </div>
          <div class="sla-table px-2 breach-table">
            <w-table
              :tableProperties="slaBreachingTable"
              @goToOrderDetailsPageEvent="goToOrderDetailsPage($event)"
            />
          </div>
        </b-card>
      </section>

      <section class="sla-section" v-if="isPermission('allow_view_sla_breached_orders')">
        <b-card>
          <div class="d-flex justify-content-between align-items-center px-3 py-3 sla-heading">
            <strong>SLA breached orders</strong> <img class="info-icon ml-1 mr-auto" src="../../assets/info-icon.png" v-b-popover.hover.right="tooltips.slaBreached">
            <p class="mb-0">
              <button
                v-if="isPermission('allow_download_sla_breached_orders')"
                class="download-for-desktop"
                id="sla-downlaod"
                style="color: #2e8cd3"
                @click="downloadSLAOrders('breached')"
                :disabled="isSLADownloadDisabled"
              >
                Download
              </button>
               <!-- for mobile -->
              <span 
                class="download-for-mobile"
                @click="downloadSLAOrders('breached')"
                :disabled="isSLADownloadDisabled"
              >
                <img src="../../assets/download.svg" id="sla-downlaod-mob">
              </span>
            </p>
          </div>
          <div class="sla-table px-2 breach-table">
            <w-table
              :tableProperties="slaTableData"
              :tableLoading="isSlaTableLoading"
              @goToOrderDetailsPageEvent="goToOrderDetailsPage($event)"
            />
          </div>
        </b-card>
      </section>

      <div class="row m-0 heading-align" v-if="isPermission('allow_view_fast_moving_inventory_products')">
        <div class="sla-section fast-moving-product w-100">
          <b-card>
            <template v-slot:header>
              <div class="row align-items-center px-3 py-3">
                <div class="col-lg-3 col-md-3 col-12 d-flex px-4 py-2 justify-content-between">
                  <strong class="mb-0">Inventory of fast moving products</strong>
                  <!-- for mobile -->
                  <span 
                    v-if="isPermission('allow_download_fast_moving_inventory_products')"
                    class="download-for-mobile"
                    @click="downloadFastMovingProduct"
                    :disabled="isFastDownloadDisabled"
                  >
                    <img src="../../assets/download.svg" id="inventory-downlaod">
                  </span>
                </div>
                <div class="col-lg-3 col-md-3 col-12 d-flex align-items-center">
                  <label class="tree-label">Channel: </label>
                  <w-tree-dropdown
                    class="ml-1"
                    :labelText="''"
                    :rootOptions="channelListOptions"
                    :selectedValue="channelListValues"
                    @selectedField="selectedChannelsCategory($event)"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-12 d-flex align-items-center">
                  <label class="tree-label">Range: </label>
                  <w-dropdown
                    :label-text="''"
                    class="w-100 ml-1"
                    :selectedValue="selectedRange"
                    :selectOption="options"
                    @selectedOption="onSelectedRange($event)"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-12 ml-auto text-right download-for-desktop">
                  <button
                    v-if="isPermission('allow_download_fast_moving_inventory_products')"
                    style="color: #2e8cd3;"
                    id="fast-downlaod"
                    @click="downloadFastMovingProduct"
                    :disabled="isFastDownloadDisabled"
                  >
                    Download
                  </button>
                </div>
              </div>
            </template>
            <div class="sla-table fast-moving-tabel">
              <w-table
                :tableProperties="inventoryFastMovingTable"
                :tableLoading="isFastMovingTableLoading"
              />
            </div>
          </b-card>
        </div>
      </div>
      <div class="row m-0 heading-align" v-if="isPermission('allow_view_products_zero_order')">
        <div class="sla-section fast-moving-product w-100">
          <b-card class="zero-product">
            <template v-slot:header>
              <div class="row align-items-center px-4 py-3">
                <div class="col-md-4 col-12 px-3 pb-2 d-flex justify-content-between">
                  <strong>Products with zero order</strong>
                  <!-- for mobile -->
                  <span 
                    v-if="isPermission('allow_download_products_zero_order')"
                    class="download-for-mobile"
                    @click="downloadProductWithZeroOrder"
                    :disabled="isZeroDownloadDisabled"
                  >
                    <img src="../../assets/download.svg" id="zero-downlaod">
                  </span>
                </div>
                <div class="col-md-3 col-12 d-flex align-items-center">
                  <label class="tree-label mr-1">Channels: </label>
                  <w-tree-dropdown
                    :labelText="''"
                    :rootOptions="channelListOptions"
                    :selectedValue="zeroOrderChannelIDS"
                    @selectedField="selectedChannelsForZeroOrder($event)"
                  />
                </div>
                <div class="col-md-5 col-12 text-right download-for-desktop">
                  <button
                    v-if="isPermission('allow_download_products_zero_order')"
                    style="color: #2e8cd3;"
                    id="zero-downlaod-desk"
                    @click="downloadProductWithZeroOrder"
                    :disabled="isZeroDownloadDisabled"
                  >
                    Download
                  </button>
                </div>
              </div>
            </template>
            <div class="sla-table">
              <w-table
                :tableProperties="productWithZeroOrderTable"
                :tableLoading="isProductTableLoading"
              />
            </div>
          </b-card>
        </div>
      </div>

      <!-- Listings Table section -->
      <section class="sla-section" v-if="isPermission('allow_view_listing_status_summary')">
        <b-card>
          <div class="sla-heading px-4 py-3">
            <strong>Listings</strong>
          </div>
          <div class="sla-table listing-table">
            <w-table
              :tableProperties="listingProductTable"
              :tableLoading="isListingTableLoading"
              :isListingOperation="true"
            />
          </div>
        </b-card>
      </section>

    </div>
    <div class="order-detail-page" v-else>
      <w-Order-Details-Table
        :orderIdParams="orderDetialsParams.orderId"
        :channelIdParams="orderDetialsParams.channelId"
        :companyIdParams="orderDetialsParams.companyId"
        @gotoBackPage="goBackToOrder()"
      />
    </div>
    <!-- modal for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import DoughnutChart from "../../widgets/DoughnutChart";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import TreeDropdown from "../../widgets/TreeDropdown";
import OrderDetailsTable from "../../widgets/OrderDetailsTables";
import Dropdown from "../../widgets/Dropdown";
import {
  getSlaBreachedOrdersList,
  downloadOperationOrders,
  getInventoryFastMovingTable,
  getDownloadFastMovingProduct,
  getProductWithZeroOrdersTable,
  getDownloadProductZeroOrder,
  getListingTableList,
  getChannelList,
} from "../../Service/DashboardService";
import { isAuthorized } from "../../Service/CommonService";
export default {
  props:["isGetData"],
  components: {
    DoughnutChart,
    "w-table": TableWithCheckbox,
    "w-loading-spinner": LoadingSpinner,
    "w-tree-dropdown": TreeDropdown,
    "w-Order-Details-Table": OrderDetailsTable,
    Modal,
    "w-dropdown": Dropdown
  },
  data() {
    return {
      tooltips: {
        slaBreaching: 'Orders breaching SLA. The unshipped orders which have the ship by date as the next day. Need to schedule the pickup or confirm shipment within next 24 hours.',
        slaBreached: 'SLA breached order. The unshipped orders which has passed the ship by date.',

      },
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      isSlaTableLoading:false,
      isFastMovingTableLoading:false,
      isProductTableLoading:false,
      isListingTableLoading:false,

      loadingWidget: false,
      // table data for SLA breached order
      isSLADownloadDisabled: true,
      isFastDownloadDisabled: true,
      isZeroDownloadDisabled: true,
      isOrderDetailsTab: false,
      orderDetialsParams: null,

      zeroOrderChannelIDS: [],
      channelListValues: [],
      channelListOptions: [],
      selectedRange: "",
      options: [
        { value: "", text: "Select" },
        { value: "5", text: "5" },
        { value: "10", text: "10" },
        { value: "15", text: "15" },
      ],

      slaTableData: {
        items: [],
        fields: [
          {
            key: "channel",
            label: "Channel Name",
          },
          {
            key: "orderId",
            label: "Order ID",
          },
          {
            key: "shipmentType",
            label: "Type",
          },
          {
            key: "masterSku",
            label: "SKU",
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
          },
          {
            key: "timeAfterSla",
            label: "Time Elapsed",
          },
        ],
      },

      slaBreachingTable: {
        items: [],
        fields: [
          {
            key: "channel",
            label: "Channel Name",
          },
          {
            key: "orderId",
            label: "Order ID",
          },
          {
            key: "shipmentType",
            label: "Type",
          },
          {
            key: "masterSku",
            label: "SKU",
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
          },
        ],
      },

      // tabel for inventory fast moving products
      inventoryFastMovingTable: {
        items: [],
        fields: [
          {
            key: "serialNumber",
            label: "S. No.",
          },
          {
            key: "masterSku",
            label: "Master SKU",
            class: ["text-center","text-nowrap"]
          },
          {
            key: "channelName",
            label: "Channel Name",
            class: ["text-center","text-nowrap"]
          },
          {
            key: "title",
            label: "Title",
          },
          {
            key: "inventory",
            label: "Inventory",
          },
          {
            key: "totalSales",
            label: "Total Sales",
            thClass: "text-wrap"
          },
          {
            key: "daysOfInventory",
            label: "Days Of Inventory",
            thClass: "text-wrap"
          },
        ],
      },
      // tabel for products with zero order
      productWithZeroOrderTable: {
        items: [],
        fields: [
          {
            key: "sno",
            label: "S. No.",
          },
          {
            key: "masterSku",
            label: "Master SKU",
            class: ["text-center","text-nowrap"]
          },
          {
            key: "channelName",
            label: "Channel Name",
            class: ["text-center","text-nowrap"]
          },
          {
            key: "title",
            label: "Title",
            class: "text-left"
          },
          {
            key: "inventory",
            label: "Inventory",
          },
        ],
      },
      // table for listing product
      listingProductTable: {
        items: [],
        fields: [
          {
            key: "channelName",
            label: "Channel Name",
          },
          {
            key: "totalListings",
            label: "Total Listings",
          },
          {
            key: "mappedListings",
            label: "Mapped",
          },
          {
            key: "unmappedListings",
            label: "Unmapped",
          },
          {
            key: "listingPercenatage",
            label: "Listing Percentage",
          },
        ],
      },
    };
  },

  created() {
    // this.getChannelList();
    // this.getSlaBreachedOrdersList();
    // this.getFastMovingProductTable();
    // this.getProductWithZeroOrders();
    // this.getListingTableList();
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    getData(){
      this.getChannelList();
      this.getSlaBreachedOrdersList();
      this.getFastMovingProductTable();
      this.getProductWithZeroOrders();
      this.getListingTableList();
    },

    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelListOptions.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getSlaBreachedOrdersList() {
      this.isSlaTableLoading = true;
      getSlaBreachedOrdersList()
        .then((res) => {
          this.isSlaTableLoading = false;
          this.slaTableData.items = res.breachedOrders.content || [];
          this.slaBreachingTable.items = res.breachingOrders.content || [];

          if (
            this.slaTableData.items.length > 0 ||
            this.slaBreachingTable.items.length > 0
          ) {
            this.isSLADownloadDisabled = false;
          }
        })
        .catch((err) => {
          this.isSlaTableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    downloadSLAOrders(type) {
      this.loadingWidget = true;
      downloadOperationOrders(type)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = "Downloaded Successfully";
          this.alertType = "Success";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getFastMovingProductTable() {
      this.isFastMovingTableLoading = true;
      getInventoryFastMovingTable(this.channelListValues, this.selectedRange, this.companyID)
        .then((res) => {
          this.isFastMovingTableLoading = false;
          this.inventoryFastMovingTable.items = res;

          if (this.inventoryFastMovingTable.items.length > 0) {
            this.isFastDownloadDisabled = false;
          }
        })
        .catch((err) => {
          this.isFastMovingTableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    downloadFastMovingProduct() {
      this.loadingWidget = true;
      getDownloadFastMovingProduct(this.channelListValues, this.companyID)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Success";
          this.alertMsg = "Download successfully";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          console.log("fast moving error", err);
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Failure";
          this.alertMsg = err.message;
          this.isConfirmAlert = false;
        });
    },

    getProductWithZeroOrders() {
      this.isProductTableLoading = true;
      getProductWithZeroOrdersTable(this.zeroOrderChannelIDS, this.companyID)
        .then((res) => {
          this.isProductTableLoading = false;
          this.productWithZeroOrderTable.items = res.map((el, index) => ({
            ...el,
            sno: 1 + index,
          }));

          if (this.productWithZeroOrderTable.items.length > 0) {
            this.isZeroDownloadDisabled = false;
          }
        })
        .catch((err) => {
          this.isProductTableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    downloadProductWithZeroOrder() {
      this.loadingWidget = true;
      getDownloadProductZeroOrder(this.zeroOrderChannelIDS, this.companyID)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Success";
          this.alertMsg = "Download successfully";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          console.log("zero order error", err);
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Failure";
          this.alertMsg = err.message;
          this.isConfirmAlert = false;
        });
    },

    getListingTableList() {
      this.isListingTableLoading = true;
      getListingTableList()
        .then((res) => {
          this.isListingTableLoading = false;
          this.listingProductTable.items = res;
        })
        .catch((err) => {
          this.isListingTableLoading = false;
          this.alert = true;
          this.alertType = "Failure";
          this.alertMsg = err.message;
          this.isConfirmAlert = false;
        });
    },

    selectedChannelsCategory(event) {
      this.channelListValues = event;
      this.getFastMovingProductTable();
    },
    onSelectedRange(event) {
      this.selectedRange = event;
      this.getFastMovingProductTable();
    },
    selectedChannelsForZeroOrder(event) {
      this.zeroOrderChannelIDS = event;
      this.getProductWithZeroOrders();
    },
    // go to order details page
    goToOrderDetailsPage(orderDetails) {
      console.log("order detail", orderDetails);
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },
    goBackToOrder() {
      this.isOrderDetailsTab = false;
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.info-icon {
  height:18px;
  margin-bottom:1px;
  cursor: default;
}
.card{
  box-shadow: 0px 1px 10px #40404029;
  margin: 1rem 0rem !important;
}
.fast-moving-product ::v-deep .card-header{
  padding: 0;
  margin-bottom:10px;
}
.sla-section ::v-deep .card .card-body {
  padding: 0 0 8px !important;
}
.sla-section .sla-heading {
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
}
.sla-section ::v-deep .card-header {
  background: transparent;
}
.sla-table{
  padding: 0px 15px;
}
.sla-table ::v-deep .card {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  
}
.sla-table ::v-deep div div:first-child {
  margin-top: 0 !important;
}
.sla-table ::v-deep div div:first-child {
  margin-top: 0 !important;
}
.heading-align ::v-deep .table thead tr th:nth-child(1) {
  text-align: left;
}
.heading-align ::v-deep .table thead tr th:nth-child(2) {
  text-align: left;
}
.heading-align ::v-deep .table thead tr th:nth-child(3) {
  text-align: left;
}
.heading-align ::v-deep .table tbody tr td:nth-child(1) {
  text-align: left;
}
.heading-align ::v-deep .table tbody tr td:nth-child(2) {
  text-align: left;
}
.heading-align ::v-deep .table tbody tr td:nth-child(3) {
  text-align: left;
}
.fast-moving-tabel ::v-deep .table tbody tr td:nth-child(4) {
  text-align: left;
}

.sla-table ::v-deep .table thead tr th {
  text-align: center;
  white-space: nowrap;
}
.sla-table ::v-deep .table tbody tr td {
  text-align: center;
}
.sla-table ::v-deep .tableStyle {
  height: 300px !important;
}
.listing-table ::v-deep table thead tr th:first-child {
  text-align: left;
}
.listing-table ::v-deep table tbody tr td:first-child {
  text-align: left;
}
.breach-table ::v-deep table thead tr th:first-child {
  text-align: left;
}
.breach-table ::v-deep table tbody tr td:first-child {
  text-align: left;
}
.order-detail-page ::v-deep .whithout-tab-content{
  margin: 0;
}
/* .zero-product ::v-deep .card-header {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
} */
.operation-dashboard label{
  margin-bottom: 0;
}
.download-for-mobile{
  display: none;
}

@media only screen and (max-width: 767.98px){
  .operation-dashboard{
    margin-top: 70px;
  }
  .download-for-desktop{
    display: none;
  }
  .download-for-mobile{
    display: block;
  }
}
</style>
