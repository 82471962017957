<template>
<div style="position: relative; height:16vh; width:22vw; borderWidth: 15px;float: left;">
  <canvas id="myChart"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js';
export default {
  mounted() {
    var ctx = document.getElementById("myChart").getContext("2d");
    var myChart = new Chart(ctx, {
      type: "doughnut",
      radius: "20%",
      innerRadius: "75%",
      data: {
        labels: ["Red", "Blue", "Red", ],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3, ],
            backgroundColor: [
              // "rgba(255, 99, 132, 0.2)",
              // "rgba(54, 162, 235, 0.2)",
              // "rgba(255, 206, 86, 0.2)",
              "rgb(0,48,143)",
              "rgb(59,122,87)",
              "red"
            ],
            borderColor: [
              // "rgba(255, 99, 132, 1)",
              // "rgba(54, 162, 235, 1)",
              // "rgba(255, 206, 86, 1)",
               "rgb(0,48,143)",
              "rgb(59,122,87)",
              "red"
            ],
            borderWidth: .5
          }
        ]
      },
       options: {
         cutoutPercentage: 80
       }
      //   scales: {
      //     yAxes: [
      //       {
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }
      //     ]
      //   }
      // }
    });
    // console.log(myChart)
  }
};
</script>

<style>
#canvas-holder {
        background-color: #FFFFFF;
        position: absolute;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        border: solid 1px red;
    }
</style>