<template>
  <div class="status-card" :style="getStyleBorder()">
    <!-- <div class="row p-0 m-0 mx-1"> -->
      <div class="p-2 card-wrapper">
        <p class="card-title mb-0">{{cardTitle}} <img class="ml-1 mr-auto info-icon" src="../assets/info-icon.png" v-b-popover.hover.top="getTooltip(cardTitle)"> <img v-if="icon != false" class="float-right" :src="getImgURL(icon)" ></p>
        <div class=" mb-0 " :style="getStyle()" style=" font-size:20px; font-weight:600"> 
          <p class="mb-0 py-2" >{{orders}}</p>
          <!-- <p class="mb-0 py-2" v-if="cardStyle==='total-order'"  >{{orders}}</p>
          <p class="mb-0 py-2" v-if="cardStyle==='returned-order'" >{{orders}}</p>
          <p class="mb-0 py-2" v-if="cardStyle==='cancelled-order'" >{{orders}}</p>
          <p class="mb-0 py-2" v-if="cardStyle==='items-sold'"  >{{orders}}</p>
          <p class="mb-0 py-2" v-if="cardStyle==='average-per'"  >{{orders}}</p> -->
        </div>
        <p class="total-usage row mb-0 mt-auto mr-1 d-flex justify-content-between p-0 m-0" v-if="totalUsage" :style="getStyle()" >
          <span class="col-lg-6 col-md-6 col-6 p-0 m-0 percentage-prop font-weight-normal">Revenue</span>
          <span class="col-lg-6 col-md-6 col-6 p-0 m-0 percentage-prop"> {{totalUsage}}</span>
        </p>
      </div>
      <!-- <div class="p-0 m-0">
        
      </div> -->
    <!-- </div> -->
    <!-- <div class="row p-0 m-0 mt-4 d-flex justify-content-between"> 
      <span v-if="activeAndInactive" class="usage-count card-active-status">ACTIVE : {{activeUsage}}</span>
      <span v-if="activeAndInactive" class="usage-count card-inactive-status">INACTIVE : {{inactiveUsage}}</span>
      <span v-if="cardStyle=='end-users' && inactiveUsage > 0">
        <i class="fa fa-caret-down my-auto" :class="'negative-percent'"></i>
        <span class="pl-1"><span class="negative-percent">{{ inactiveUsage + "%" }}</span> Since last month</span>
      </span>
      <span v-if="cardStyle=='end-users' && activeUsage > 0">
        <i class="fa fa-caret-up my-auto" :class="'positive-percent'"></i>
        <span class="pl-1"><span class="positive-percent">{{ activeUsage + "%" }}</span> Since last month</span>
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  props:["cardStyle","cardTitle","totalUsage","orders","routePath","icon"],
  data() {
    return {
      tooltips: {
        total_orders: 'Total number of orders in the given duration',
        cancelled_orders: 'Total number of cancelled orders in the given duration',
        returned_orders: 'Total number of returned orders in the given duration',
        items_sold: 'Number of products sold in the specified duration. Total product sales/Unit price.',
        average_per_day: 'Average orders per day'
      }
    }
  },
  methods:{
    getImgURL(iconName){
      
      var images = require.context("../assets/Dashboard/", false, /\.svg$/);
      return images("./" + iconName + ".svg");
    },
    getStyleBorder(){
      switch (this.cardStyle){
        case "total-order":
          return "border-color: #1D408E;"
        case "returned-order":
          return "border-color: #EDBD1B;"
        case "cancelled-order":
          return "border-color: #F43B3B;"
        case "items-sold":
          return "border-color: #741BED;"
         case "average-per":
          return "border-color: #60C7FD;"
      }
    },

    getStyle(){
      switch (this.cardStyle){
        case "total-order":
          return "color:#1B1C1D;"
        case "returned-order":
          return "color:#1B1C1D;"
        case "cancelled-order":
          return "color:#1B1C1D;"
        case "items-sold":
          return "color:#1B1C1D;"
         case "average-per":
          return "color:#1B1C1D;"
      }
    },
    getTooltip(title) {
      title = title.toLowerCase().replaceAll(' ','_');
      return this.tooltips[title];
    },
    routeTo(){
      if(this.routePath){
        this.$router.push(this.routePath)
      }
    }
  }
}
</script>

<style scoped>
.info-icon {
  height: 15px;
  cursor: default;
  margin-top:1px;
}
.status-card {
  /* background-color: #ffffff; */
  /* max-width: 20%; */
  /* width: 19.43%; */
  height: 100%;
  /* max-height: 8rem; */
  border-radius: 5px;
  box-shadow: 0px 1px 10px #40404029;
  border:2px;
  border-top:0px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 5px;
  border-style: solid;
  background-color: #FFFFFF;
}
/* .img-container {
  text-align: center;
  font-size: 16px;
  padding: 6px;
  margin-top: 5px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
} */
.card-title {
  color: #1B1C1D;
  font-size: 12px;
  /* font-weight: 700; */
  font-family: "proxima-nova", sans-serif !important;
  letter-spacing: 0.5px;
  /* cursor: pointer; */
  display: flex;
  justify-content: space-between;
}
.total-usage {
  /* padding-top: 9px; */
  font-size: 14px;
  /* color: #212529; */
  /* letter-spacing: -0.06px; */
  font-weight: 700;
}
.total-usage span{
  white-space: nowrap;
}
.total-usage span:last-child{
  text-align: right;
}
/* .card-img {
  margin-top: 23px
} */
.card-wrapper{
  display: flex;
  flex-direction: column;
}
.percentage-prop{
  font-size: 12px;
}
@media screen and (max-width: 1100px){
  .percentage-prop{
    font-size: 10px;
  }
}
</style>