<template>
  <div class="product-dashboard">
    <!-- filter for mobile -->
    <div class="open-mobile-filter" v-if="isMobile_filter_open">
      <div class="row mx-2">
        <div class="col-2 pt-4">
          <span @click="closeApplyFilter">
            <img src="../../assets/SVG/arrow-back.svg" >
          </span>
        </div>
        <div
          class="col-8 pt-0 d-flex justify-content-center"
          style="border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
        >
          <span
            class="text-white bg-dark py-3 px-4"
            style="font-size:20px; border-bottom-left-radius: 50%; border-bottom-right-radius: 50%;"
            >LOGO</span
          >
        </div>
        <div class="col-2"></div>
      </div>
      
      <b-card class="mx-2 mt-5" >
        <div class="row">
          <div class="col-md-3 date-picker">
            <label class="tree-label">Date Range </label>
            <date-picker
              class=""
              id="product-date"
              v-model="rangepicaker"
              type="date"
              lang="en"
              confirm
              range
              :shortcuts="dateShortcuts"
              @clear="clearDateRange($event)"
              @confirm="getSelectedDateRange($event)"
            ></date-picker>
          </div>

          <div class="col-md-3">
            <w-tree-dropdown
              :labelText="'Channels'"
              class=""
              :rootOptions="channelListOptions"
              :selectedValue="channelListValues"
              @selectedField="selectedChannelsCategory($event)"
            />
          </div>
          <div class="col-md-3 performance-based">
            <label class="tree-label">Performance based on</label>
            <w-dropdown
              class=""
              :labelId="'product'"
              :label-text="''"
              :selectedValue="null"
              :selectOption="sortByOptions"
              @selectedOption="onSelectSortedBy($event)"
            />
          </div>
        </div>
      </b-card>
    </div>
    <!-- mobile filter end -->

    <div>
      <!-- filter button for mobile -->
      <!-- <div class="mobile-filter">
        <div class="text-right">
          <p class="mb-0 mx-3 text-primary">
            <span class="apply-filter" @click="onApplyFilter"
              >
                <img src="../../assets/SVG/filter-results-button.svg" >
              </span
            >
          </p>
        </div>
      </div> -->
      <!-- end -->
        
      <!-- <div class="col-md-2 my-4 py-0" @click="showProductFilter">
        <img src="../../assets/SVG/desktopFilter.svg" style="cursor:pointer" />
        <span style="color:#2E8CD3; font-size:14px; cursor:pointer">Filters</span>
      </div> -->
      <div class="dashboard-filter">
        <b-card class="m-2 desktop-filter" v-if="isProductFilter">
          <div class="row d-flex">
            <div class="col-md-3 date-picker">
              <label class="tree-label">Date Range </label>
              <date-picker
                class=""
                id="filter-dateRange"
                v-model="rangepicaker"
                type="date"
                lang="en"
                confirm
                range
                :shortcuts="dateShortcuts"
                @clear="clearDateRange($event)"
                @confirm="getSelectedDateRange($event)"
              ></date-picker>
            </div>

            <div class="col-md-3">
              <w-tree-dropdown
                :labelText="'Channels'"
                class=""
                :rootOptions="channelListOptions"
                :selectedValue="channelListValues"
                @selectedField="selectedChannelsCategory($event)"
              />
            </div>
            <div class="col-md-3 performance-based">
              <label class="tree-label">Performance based on</label>
              <w-dropdown
                class=""
                :labelId="'per-product'"
                :label-text="''"
                :selectedValue="null"
                :selectOption="sortByOptions"
                @selectedOption="onSelectSortedBy($event)"
              />
            </div>
          </div>
          <img
            class="mt-3 close-filter"
            src="../../assets/CloseModel.svg"
            width="15px"
            style="cursor:pointer"
            @click="closeDesktopFilter"
          />
        </b-card>
      </div>

      <!-- <div class="performance-search-group m-2">
        <w-dropdown
          style="width:250px"
          :label-text="''"
          :selectedValue="null"
          :selectOption="performanceOptions"
          @selectedOption="onChangePerformance($event)"
        />

        <div class="search-input" style="width:35%">
          <b-form-input
            type="search"
            placeholder="Search by product title..."
            @keyup="getProductFilter($event)"
          ></b-form-input>
        </div>
      </div> -->

      <!-- <b-card class="m-2 product-table"> -->
        <w-table
          :tableProperties="productTables"
          :tableLoading="tableLoading"
          :filterSearch="filterSearch"
          :filterFields="filterFields"
          :isFilterButton="true"
          @getSelectedPageEvent="getSelectedPage($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          :isProductDashboard="true"
          :performanceOptions="performanceOptions"
          @onChangePerformanceEvent="onChangePerformance($event)"
          @getProductFilterEvent="getProductFilter($event)"
          @openDesktop_filterEvent="showProductFilter"
        />
      <!-- </b-card> -->
    </div>

    <!-- modal for error handling -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import TextInput from "../../widgets/TextInput.vue";
import DatePicker from "vue2-datepicker";
import TreeDropdown from "../../widgets/TreeDropdown";
import Dropdown from "../../widgets/Dropdown.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import {
  getChannelList,
  getProductSorting,
  getProductTableList,
  getPerformanceOption,
} from "../../Service/DashboardService";
export default {
  props:["isGetData"],
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    DatePicker,
    "w-tree-dropdown": TreeDropdown,
    "w-table": TableWithCheckbox,
    Modal,
  },
  data() {
    return {
      companyID: sessionStorage.getItem("companyIds"),
      isMobile_filter_open: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,

      rangepicaker: "",
      startDate: "",
      endDate: "",
      dateShortcuts: [
        {
          text: "Today",
          onClick: () => {
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            let endofDay = new Date();
            endofDay.setHours(23, 59, 59, 999);

            this.rangepicaker = [startOfDay, endofDay];
            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "Last Week",
          onClick: () => {
            let d1 = new Date(); // get current date
            let first = d1.getDate() - d1.getDay(); // First day is the day of the month - the day of the week

            let last = first - 6; // last day is the first day + 6
            let firstday = new Date(d1.setDate(first));
            firstday = new Date(firstday.setHours(23, 59, 59, 999));

            let d2 = new Date();
            let lastday = new Date(d2.setDate(last));
            lastday = new Date(lastday.setHours(0, 0, 0, 0));

            this.rangepicaker = [lastday, firstday];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            last = new Date(last.setHours(0, 0, 0, 0));

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "This Month",
          onClick: () => {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

            let lastDay = new Date();

            this.rangepicaker = [firstDay, lastDay];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            let now = new Date();
            let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
            let firstday = new Date(
              lastday.getFullYear(),
              lastday.getMonth(),
              1
            );
            this.rangepicaker = [firstday, lastday.setHours(23, 59, 59, 999)];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "This Quarter",
          onClick: () => {
            let todays = new Date();
            let thisMonth = todays.getMonth();
            let startMonth =
              thisMonth > 2 ? (thisMonth > 5 ? (thisMonth > 8 ? 9 : 6) : 3) : 0;
            let startCurrentQuarter = new Date(
              todays.getFullYear(),
              startMonth,
              1
            );
            let endCurrentQuarter = new Date(
              startCurrentQuarter.getFullYear(),
              startMonth + 3,
              0
            );
            endCurrentQuarter.setHours(23, 59, 59, 999);

            this.rangepicaker = [startCurrentQuarter, endCurrentQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
        {
          text: "Last Quarter",
          onClick: () => {
            let todays = new Date();
            let thisMonth = todays.getMonth() + 1;
            let thisYear = todays.getFullYear();
            let startMonth =
              thisMonth > 3
                ? thisMonth > 6
                  ? thisMonth > 9
                    ? 7
                    : 4
                  : 1
                : 10;
            let startYear = thisMonth > 3 ? thisYear : thisYear - 1;
            let startLastQuarter = new Date(startYear, startMonth - 1, 1);
            let endLastQuarter = new Date(
              startLastQuarter.getFullYear(),
              startLastQuarter.getMonth() + 3,
              0
            );
            endLastQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startLastQuarter, endLastQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getProductTableList();
          },
        },
      ],
      selectedDateType: "",

      channelListOptions: [],
      channelListValues: [],

      sortByOptions: [],
      selectedSortBy: "Revenue-wise",

      performanceOptions: [],
      selectedPerformance: "BestPerforming",

      filterSearch: "",
      filterFields: [],

      productTables: {
        items: [],
        fields: [
          {
            key: "productTitle",
            label: "Products",
          },
          {
            key: "marketPlace",
            label: "Marketplace",
          },
          {
            key: "masterSku",
            label: "Master SKU",
          },
          {
            key: "sellerSku",
            label: "Seller SKU",
            class: "text-left"
          },
          {
            key: "quantitysold",
            label: "Quantity Sold",
          },
          {
            key: "revenue",
            label: "Revenue",
            class: ["text-nowrap", "text-right"]
          },
          {
            key: "averageOrdersPerDay",
            label: "Average Orders Per Day",
          },
          {
            key: "averageRemittedAmountPerQuantitySold",
            label: "Average Remitted Value",
          },
          {
            key: "avgRemittedPercentage",
            label: "Average Remitted %",
          },
        ],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
      isProductFilter:false
    };
  },

  created() {
    // // default 7 days date range
    // let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // this.rangepicaker = [first, last];
    // this.startDate = moment(this.rangepicaker[0]).valueOf();
    // this.endDate = moment(this.rangepicaker[1]).valueOf();
    // this.selectedDateType = "Custom";

    // this.getChannelList();
    // this.getProductSorting();
    // this.getProductTableList();
    // this.getPerformanceOption();
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {
    getProductFilter(event) {
      this.filterFields = [];
      this.filterFields = ["productTitle", "masterSku"];
      this.filterSearch = event.target.value;
    },
    getData(){
      // default 7 days date range
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.rangepicaker = [first, last];
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";

      this.getChannelList();
      this.getProductSorting();
      this.getProductTableList();
      this.getPerformanceOption();
    },
    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          res.forEach((element) => {
            this.channelListOptions.push({
              id: element.channelId,
              label: element.channelName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getProductSorting() {
      this.sortByOptions = [];
      this.sortByOptions.push({
        value: null,
        text: "Revenue-wise",
      });

      getProductSorting()
        .then((res) => {
          let resp = res.response;
          resp.forEach((el) => {
            this.sortByOptions.push({
              value: el.sortingValue,
              text: el.text,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // product tables list
    getProductTableList() {
      this.tableLoading = true;
      getProductTableList(
        this.startDate,
        this.endDate,
        this.selectedDateType,
        this.channelListValues,
        this.selectedSortBy,
        this.selectedPerformance,
        this.productTables.currentPage - 1,
        this.productTables.perPage,
        this.companyID
      )
        .then((res) => {
          this.tableLoading = false;
          let resp = res.response.content;
          this.productTables.items = resp || [];

          this.productTables.totalRows = res.response.totalElements;
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // performance api
    // selectPerformance(performance) {
    //   this.selectedPerformance = "";
    //   getPerformanceOption()
    //     .then((res) => {
    //       let resp = res.response;
    //       if (resp[0].performanceValue === performance) {
    //         this.selectedPerformance = resp[0].performanceValue;
    //         this.getProductTableList();
    //       } else {
    //         this.selectedPerformance = resp[1].performanceValue;
    //         this.getProductTableList();
    //       }
    //     })
    //     .catch((err) => {
    //       this.alert = true;
    //       this.alertMsg = err.message;
    //       this.alertType = "Failure";
    //       this.isConfirmAlert = false;
    //     });
    // },

    getPerformanceOption() {
      this.performanceOptions = [];
      this.performanceOptions.push({
        value: null,
        text: "Best Performing",
      });

      getPerformanceOption()
        .then((res) => {
          let resp = res.response;
          resp.forEach((el) => {
            this.performanceOptions.push({
              value: el.performanceValue,
              text: el.text,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    onChangePerformance(event) {
      this.selectedPerformance = event;
      console.log("selected", this.selectedPerformance);
      this.getProductTableList();
    },

    selectedChannelsCategory(event) {
      this.channelListValues = event;
      this.getProductTableList();
    },
    // performance based on dropdown
    onSelectSortedBy(event) {
      this.selectedSortBy = event;
      this.getProductTableList();
    },

    // for pagination
    getSelectedPerPage(perPage) {
      this.productTables.perPage = perPage;
      this.productTables.currentPage = 1;
      this.getProductTableList();
    },
    getSelectedPage(currentPage) {
      this.productTables.currentPage = currentPage;
      this.getProductTableList();
    },

    // clear date
    clearDateRange() {
      let first = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      this.rangepicaker = [first, last];
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";

      this.getProductTableList();
    },
    // selected custom date range
    getSelectedDateRange() {
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
      this.getProductTableList();
    },

    // close modal
    closeModal() {
      // this.showModal = false;
      this.isTablePopUp = false;
      this.alert = false;
    },

    // open filter for mobile
    // onApplyFilter() {
    //   this.isMobile_filter_open = true;
    // },
    closeApplyFilter() {
      this.isMobile_filter_open = false;
    },
    showProductFilter(){
      this.isProductFilter = !this.isProductFilter;
    },
    closeDesktopFilter(){
      this.isProductFilter = false;
    },
  },
};
</script>
<style scoped>
/* .dashboard-filter{
  position: absolute;
  z-index: 200;
} */
.dashboard-filter ::v-deep .card {
  border-radius: 5px;
  padding: 10px 0px;
}
.dashboard-filter ::v-deep .custom-select {
  height: auto;
}
/* .dashboard-filter ::v-deep .card-body {
  padding-top: 0;
} */
.performance-search-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-table ::v-deep .card-body {
  padding: 0 7px;
}
.product-table ::v-deep .card-body .userTable .card {
  margin-top: 0 !important;
}
.product-table ::v-deep .card-body .row {
  margin-bottom: 0 !important;
}
.product-table ::v-deep .theadClass {
  text-align: center;
}
.product-table ::v-deep .table thead tr th:first-child {
  text-align: left !important;
}
.product-table ::v-deep .table thead tr th:nth-child(7) {
  width: 9% !important;
}
.product-table ::v-deep .table thead tr th:nth-child(8) {
  width: 9% !important;
}
.product-table ::v-deep .table thead tr th:nth-child(9) {
  width: 7% !important;
}
.product-table ::v-deep .table tbody tr td {
  text-align: center !important;
}

.product-table ::v-deep .table tbody tr td:first-child {
  width: 25% !important;
  text-align: left !important;
}
/* .search-input ::v-deep .form-control {
  font-size: 14px;
} */
.performance-based ::v-deep .select_style .custom-select option:first-child {
  display: none;
}
/* .performance-search-group
  ::v-deep
  .select_style
  .custom-select
  option:first-child {
  display: none;
} */
.performance-search-group ::v-deep .form-control {
  height: 37px !important;
}
.performance-search-group ::v-deep .custom-select {
  height: 37px;
}
.mobile-filter {
  display: none;
}
.open-mobile-filter {
  display: none;
}
.product-dashboard ::v-deep .userTable tbody tr td:nth-child(1) {
    text-align: left !important;
  }
.product-dashboard ::v-deep .userTable tbody tr td {
    text-align: center;
  }
/* for responsive */
@media only screen and (min-width:768px) and (max-width:1200px){
  .product-dashboard ::v-deep .table-pagination{
    max-width: 100%;
    margin-left: auto;
  }
}
@media only screen and (max-width: 768px) {
  .product-dashboard ::v-deep .search-input{
    padding-right: 0;
  }
  .product-table ::v-deep .card-body .userTable {
    margin-top: 12px !important;
  }
  .product-dashboard {
    margin-top: 60px;
  }
  .date-picker ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  ::v-deep .mx-calendar {
    width: 100%;
  }
  ::v-deep .mx-calendar-content {
    width: 100%;
  }
  ::v-deep .mx-datepicker-popup {
    left: 0 !important;
  }

  /* .desktop-filter {
    display: none;
  } */
  .mobile-filter {
    display: block;
    /* margin-top: 73px; */
    margin-top: 0;
  }
  .open-mobile-filter {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 222222;
  }
  /* .apply-filter {
    padding: 5px 12px;
    border: 1px solid;
    background: #fff;
    cursor: pointer;
    border-radius: 18px;
  } */
}

@media only screen and (max-width: 340px){
  .product-dashboard ::v-deep .table-pagination .page-link{
    min-width: 15px !important;
  }
}

.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
</style>
